import { useState, useEffect, useCallback, useRef } from "react";
import parse from "html-react-parser";
import Popup from "../components/common/Popup";
import {
  getBegripOmschrijvingFromKeyword,
  getBegrippenKeywords,
} from "../services/begrippen";
import { KeywordSpan, PopupContent } from "../components/Keywords";
import isEqual from "react-fast-compare";

function deepCompareEquals(prevVal, currentVal) {
  return isEqual(prevVal, currentVal);
}

function useDeepCompareWithRef(value) {
  const ref = useRef();
  if (!deepCompareEquals(value, ref.current)) {
    //ref.current contains the previous object value
    ref.current = value;
  }

  return ref.current;
}

const AutoKeywords = ({ children, augmentChildren, keywords }) => {
  const [augmentedChildren, setAugmentedChildren] = useState(children);

  const childrenRef = useDeepCompareWithRef(children);
  useEffect(() => {
    if (keywords?.length) {
      setAugmentedChildren(augmentChildren(childrenRef, keywords));
    }
  }, [childrenRef, augmentChildren, keywords]);

  return <>{augmentedChildren}</>;
};

const useAutoKeywords = () => {
  const [busy, setBusy] = useState(false);
  const [popupState, setPopupState] = useState({ show: false, content: null });
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    getBegrippenKeywords().then((data) => setKeywords(data));
  }, []);

  const showPopup = useCallback((keyword) => {
    setBusy(true);
    // setPopupState({
    //   show: true,
    //   content: <PopupContent keyword={keyword} omschrijving={null} />,
    // });
    getBegripOmschrijvingFromKeyword(keyword)
      .then((omschrijving) => {
        setPopupState({
          show: true,
          content: (
            <PopupContent keyword={keyword} omschrijving={omschrijving} />
          ),
        });
      })
      .finally(() => setBusy(false));
  }, []);

  const closePopup = () => {
    setPopupState((prev) => ({ ...prev, show: false }));
  };

  const replaceKeywords = useCallback(
    (str, keywordsArray, keyPrefix = "") => {
      if (!keywordsArray.length || !str?.length) {
        return str;
      }

      const [keyword, ...rest] = keywordsArray;
      /* regular expression to match keyword, escape regex special characters inside keyword */
      const escapedKeyword = keyword.replace(
        /[-[\]{}()*+?.,\\^$|#\s]/g,
        "\\$&"
      );
      const regEx = new RegExp(`\\b(${escapedKeyword})\\b`, "gi");
      const matchRegEx = new RegExp(`^(${escapedKeyword})$`, "gi");
      return str.split(regEx).reduce((prev, part) => {
        if (part.match(matchRegEx)) {
          return [
            ...prev,
            <KeywordSpan
              onClick={() => showPopup(keyword)}
              key={`${keyPrefix}-${keyword}-${prev.length}`}
            >
              {part}
            </KeywordSpan>,
          ];
        }

        const augmentedPart = replaceKeywords(
          part,
          rest,
          `${keyPrefix}-${prev.length}`
        );
        if (Array.isArray(augmentedPart)) {
          return [...prev, ...augmentedPart];
        }
        return [...prev, augmentedPart];
      }, []);
    },
    [showPopup]
  );

  const augmentChildren = useCallback(
    (children, keywordsArray, keyPrefix = "") => {
      if (Array.isArray(children)) {
        return children.map((child, i) =>
          augmentChildren(child, keywordsArray, `${keyPrefix}-${child.key}`)
        );
      }
      if (children?.props?.dangerouslySetInnerHTML) {
        const parsedChildren = parse(
          children.props.dangerouslySetInnerHTML.__html
        );
        return (
          <div>
            {augmentChildren(
              parsedChildren,
              keywordsArray,
              `${keyPrefix}-__html`
            )}
          </div>
        );
      }
      if (children?.props?.children) {
        return {
          ...children,
          props: {
            ...children.props,
            children: augmentChildren(
              children.props.children,
              keywordsArray,
              `${keyPrefix}-${children.key}`
            ),
          },
        };
      }
      if (typeof children === "string") {
        const result = replaceKeywords(children, keywordsArray, keyPrefix);
        return result;
      }
      return children;
    },
    [replaceKeywords]
  );

  const popupProps = {
    show: popupState.show,
    close: closePopup,
    children: popupState.content,
  };
  const autoKeywordsProps = { augmentChildren, keywords };

  return { AutoKeywords, autoKeywordsProps, Popup, popupProps, busy };
};

export default useAutoKeywords;
