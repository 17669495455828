import styled from "styled-components";
import bodemkaart from "./bodemkaart.jpg";
import useAutoKeywords from "../../hooks/useAutoKeywords";

const Bodemkaart = styled.img.attrs({ src: bodemkaart })`
  float: right;
`;

const Container = styled.div`
  ${(props) => props.busy && "cursor: wait;"}
`;

const Home = () => {
  const { AutoKeywords, autoKeywordsProps, Popup, popupProps, busy } =
    useAutoKeywords();

  return (
    <Container busy={busy}>
      <Bodemkaart />
      <AutoKeywords {...autoKeywordsProps}>
        <p>
          De Bodemkaart van Nederland, schaal 1 : 50 000, geeft tot een diepte
          van 1,2&nbsp;m onder maaiveld informatie over de verbreiding van de
          bodemkundige kenmerken:
        </p>
        <ul>
          <li>Moedermateriaal (grondsoort en afzettingswijze)</li>
          <li>Bodemvorming</li>
          <li>Aard, dikte en samenstelling van de bovengrond</li>
          <li>Aard, dikte en samenstelling van de lagen in de ondergrond</li>
          <li>Kalkverloop</li>
          <li>Aanwezigheid van afwijkende lagen</li>
        </ul>
        <br />
        <p>
          De bodemgesteldheid, zoals de samenstelling, dikte en opeenvolging van
          horizonten verschilt van plaats tot plaats. Gronden met een ongeveer
          overeenkomstige laagopbouw beschouwen we als een bodemkundige eenheid,
          kortweg de bodemeenheid. De bodemkaart geeft de verbreiding van
          bodemeenheden (zie figuur). Bodemeenheden worden op de bodemkaart
          onderscheiden met een kenmerkende kleur en een unieke bodemcode, die
          kan bestaan uit een van de volgende eenheden:
        </p>
        <ul>
          <li>
            Bodemklasse (bodemclassificatie), incidenteel uitgebreid met
            bijzondere kenmerken en/of een hellingklasse
          </li>
          <li>Samengestelde legenda-eenheid</li>
          <li>Vlak van bodemkundig belang</li>
        </ul>
      </AutoKeywords>
      <Popup {...popupProps} />
    </Container>
  );
};

export default Home;
