import { fetchEndpoint } from "./legendData";

/**
 * @typedef {Object} TBegrip
 * @property {string} id
 * @property {string} omschrijving - html
 *
 * @returns {Promise<string[]>}
 */
export function getBegrippenList() {
  return fetchEndpoint("/json/begrippen_list", (data) =>
    data.map((d) => d.begrippen_id)
  );
}

/**
 * @param {string} begripId
 * @returns {Promise<TBegrip>}
 */
export function getBegrip(begripId) {
  return fetchEndpoint(
    `/json/begrippen?par1=${encodeURIComponent(begripId)}`,
    (data) => {
      if (!data?.length) {
        return null;
      }
      if (data.length === 1) {
        return { id: begripId, ...data[0] };
      }
      return data.map((d) => {
        return { id: begripId, ...d };
      });
    }
  );
}

/**
 * @returns {Promise<string[]>}
 */
export function getBegrippenKeywords() {
  return fetchEndpoint("/json/begrippen_keys_list", (data) =>
    data.map((d) => d.keyword)
  );
}

/**
 * @param {string} keyword
 * @returns {Promise<string>}
 */
export function getBegripOmschrijvingFromKeyword(keyword) {
  return fetchEndpoint(
    `/json/begrippen_keys?par1=${encodeURIComponent(keyword)}`,
    (data) => {
      if (!data?.length) {
        return null;
      }
      if (data.length === 1) {
        return data[0].omschrijving;
      }
      return data.map((d) => d.omschrijving);
    }
  );
}
