import { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HTMLContent from "../HTMLContent";
import { useAuth } from "../../context/Auth";
import { saveContent, dataFields } from "../../services/saveContent";
import { getSamengesteldeEenheid } from "../../services/legendData";
import Notification from "../Notification";

const DetailContainer = styled.div`
  ${(props) => props.busy && `cursor: wait;`}
`;
const ItemId = styled.div`
  float: left;
  margin-right: 1em;
`;
const ItemDefinitie = styled.div``;
const ItemOmschrijving = styled.div`
  margin-top: 8px;
`;

const ItemDetailsController = () => {
  let { code } = useParams();
  const [itemData, setItemData] = useState();

  useEffect(() => {
    getSamengesteldeEenheid(code)
      .then((data) => {
        setItemData(data);
      })
      .catch(console.error);
  }, [code]);

  const onEdit = () => {
    getSamengesteldeEenheid(code)
      .then((data) => {
        setItemData(data);
      })
      .catch(console.error);
  };

  if (!itemData) {
    return null;
  }
  return (
    <ItemDetails
      id={itemData.id}
      definitie={itemData.definitie}
      omschrijving={itemData.omschrijving}
      onEdit={onEdit}
    />
  );
};

const ItemDetails = ({ id, definitie, omschrijving, onEdit }) => {
  const auth = useAuth();
  const { user } = auth;
  const adminMode = user?.adminMode;

  const [busySaving, setBusySaving] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const getHandleSave = (field) => (content) => {
    setBusySaving(true);
    saveContent("sameng_leg_eenheden", id, content, field)
      .then((result) => {
        // console.log("saveContent result:", field, result);
        // setStoredData((prev) => ({ ...prev, [field]: content }));
        setSaveError(false);
      })
      .catch((serviceError) => {
        setSaveError(serviceError.message || true);
        console.error(serviceError);
      })
      .finally(() => {
        setBusySaving(false);
        onEdit?.(content);
      });
  };

  return (
    <DetailContainer busy={busySaving}>
      <Notification show={saveError} type="error">
        Er ging helaas iets mis bij het opslaan van de gegevens.
        {Boolean(saveError && saveError !== true) && (
          <>
            <br />
            <code>{saveError}</code>
          </>
        )}
      </Notification>

      <ItemId>{id}</ItemId>
      {definitie || adminMode ? (
        <ItemDefinitie>
          <HTMLContent
            initialValue={definitie}
            onSave={getHandleSave(dataFields.definitie)}
            editable={adminMode}
          />
        </ItemDefinitie>
      ) : null}
      {omschrijving || adminMode ? (
        <ItemOmschrijving>
          <HTMLContent
            initialValue={omschrijving}
            onSave={getHandleSave(dataFields.omschrijving)}
            editable={adminMode}
          />
        </ItemOmschrijving>
      ) : null}
    </DetailContainer>
  );
};

export default ItemDetailsController;
