import { useState, useEffect } from "react";
import styled from "styled-components";
import { getAllHoofdklassen } from "../services/legendData";
import Loader from "./common/Loader";
import ArrowList from "./BodemItem/ArrowList";
import useAutoKeywords from "../hooks/useAutoKeywords";

const LiteratureLink = styled.a``;

const StyledLoader = styled(Loader)`
  margin: 32px 0px;
`;

const BodemclassificatieHome = () => {
  const { Popup, AutoKeywords, autoKeywordsProps, popupProps } =
    useAutoKeywords();
  const [hoofdklassen, setHoofdklassen] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllHoofdklassen().then((data) => {
      setHoofdklassen(data);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <h3>Bodemclassificatie</h3>
      <AutoKeywords {...autoKeywordsProps}>
        <p>
          De bodemkaart is opgebouwd uit iets meer dan 52.000 afzonderlijke
          kaartvlakken die middels een bodemcode toebehoren aan iets meer dan
          1700 unieke bodemeenheden. De bodemkaart kent een uitgebreide legenda,
          waardoor allerlei verschillen in bodemopbouw goed onderscheiden kunnen
          worden. Alle voorkomende bodemeenheden worden in deze legenda
          systematisch verklaard vanuit bodemhoofdklassen naar bodemklassen
          (legenda-eenheden).
        </p>
        <p>
          Een bodemhoofdklasse bestaat uit gronden met overeenkomstig
          moedermateriaal (grondsoort en afzettingswijze). In een aantal
          gevallen is het moedermateriaal gecamoufleerd, doordat in de naam van
          de bodemhoofdklassen de naam van het moedermateriaal ontbreekt. Dit is
          het geval bij de gronden, die naar hun bodemvorming zijn onderscheiden
          (bijvoorbeeld bij podzolgronden Hn. of brikgronden BL.). Voor zover
          het moedermateriaal niet in de bodemhoofdklasse zelf is ingesloten,
          komt deze in de onderverdeling naar bodemklassen aan de orde. De
          bodemhoofdklassen van de legenda worden op de bodemkaart gecodeerd met
          één of twee hoofdletter(s).
        </p>
        <p>
          De verdere onderverdeling van de bodemhoofdklassen naar bodemklassen
          sluit nauw aan bij die van het Systeem van bodemclassificatie voor
          Nederland [
          <LiteratureLink href="https://edepot.wur.nl/117844" target="_blank">
            De Bakker & Schelling, 1989
          </LiteratureLink>
          ] tot en met het niveau van de subgroep. Dit niveau is in de legenda
          naamgevend. De onderverdeling naar bodemklassen wordt aangegeven door
          enkele kleine letters en cijfers vóór en achter de hoofdletter(s).
          Kenmerken van de bovengrond staan gewoonlijk vóór de hoofdletter(s),
          de overige kenmerken erachter. Cijfers hebben betrekking op de textuur
          van de bovengrond en bij kleigronden ook op het profielverloop. In de
          verschillende bodemhoofdklassen kunnen dezelfde letters en cijfers een
          verschillende betekenis hebben.
        </p>
      </AutoKeywords>
      <Popup {...popupProps} />
      {loading && <StyledLoader />}
      <ArrowList
        items={hoofdklassen}
        linkBase="/bodemclassificatie/item/"
        arrowStartStraight
      />
      {/* <ul>
        {hoofdklassen.map((klasse) => {
          const { id, naam, slug } = klasse;
          return (
            <li key={id}>
              <Link to={`/bodemclassificatie/${slug}`}>
                {id}: {naam}
              </Link>
            </li>
          );
        })}
      </ul> */}
    </div>
  );
};
export default BodemclassificatieHome;
