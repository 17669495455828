import { NavigationLink } from "../BodemItem/styled";
import useAutoKeywords from "../../hooks/useAutoKeywords";
import EntityNavigator from "../EntityNavigator";
// import ListIcon from "../Icons/ListIcon";

const BijzondereKenmerken = () => {
  const { Popup, AutoKeywords, autoKeywordsProps, popupProps } =
    useAutoKeywords();

  const items = [
    {
      id: "root",
      // label: (
      //   <span>
      //     <ListIcon
      //       style={{ verticalAlign: "text-bottom", marginRight: "0.8em" }}
      //     />
      //     Bijzondere kenmerken
      //   </span>
      // ),
      label: "Bijzondere kenmerken",
      itemType: "root",
    },
  ];

  return (
    <div>
      <EntityNavigator items={items} activeNavItem={items[0]} />
      <AutoKeywords {...autoKeywordsProps}>
        <p>
          Bijzondere kenmerken zijn extra onderscheidingen (toevoegingen) bij
          een bodemklasse (legenda-eenheid) waarmee afwijkende lagen worden
          aangegeven.
        </p>
        <p>
          We onderscheiden bijzondere kenmerken bovenlaag (toevoeging voor) en
          bijzondere kenmerken onderlaag (toevoeging achter). Wanneer de
          toevoeging betrekking heeft op de bovengrond wordt het verschijnsel
          aangegeven met een letter aan de voorzijde van de code van de
          bodemklasse. Bijzondere kenmerken onderlaag (toevoeging achter) zijn
          extra onderscheidingen bij een bodemklasse (legenda-eenheid) waarmee
          afwijkende lagen in de ondergrond worden aangegeven.
        </p>
        <p>
          Bij beide toevoegingen (aan de voor- en achterzijde van de code)
          kunnen combinaties voorkomen (bijv. fk..., fk...g). Verschillende van
          deze verschijnselen treden pleksgewijs op. Deze zijn omschreven met de
          term “plaatselijk”.
        </p>
      </AutoKeywords>
      <Popup {...popupProps} />
      <NavigationLink to="boven">Kenmerken bovenlaag</NavigationLink>

      <NavigationLink to="onder">Kenmerken onderlaag</NavigationLink>
    </div>
  );
};

export default BijzondereKenmerken;
