export const hellingklassen = [
  {
    code: "A",
    omschrijving: "Vlak en bijna vlak, hellingpercentage <2%",
  },
  {
    code: "B",
    omschrijving: "Zwak hellend, hellingpercentage 2-5%",
  },
  {
    code: "C",
    omschrijving: "Matig hellend, hellingpercentage 5-8%",
  },
  {
    code: "D",
    omschrijving: "Sterk hellend, hellingpercentage 8-16%",
  },
  {
    code: "E",
    omschrijving: "Vrij steil, hellingpercentage 16-25%",
  },
  {
    code: "F",
    omschrijving: "Zeer steil, hellingpercentage >25%",
  },
];
