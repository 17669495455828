import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import { itemTypes } from "./services/legendData";
import { AuthProvider, useAuth } from "./context/Auth";
import MainLayout from "./components/MainLayout";
import NoMatch from "./views/NoMatch";
import HomeView from "./views/Home";
import Bodemklasse from "./views/Bodemklasse";
import MainItemView from "./views/MainItemView";
import BodemclassificatieHomeView from "./views/BodemclassificatieHome";
import BijzondereKenmerkenView from "./views/BijzondereKenmerken";
import BijzondereKenmerkenListView from "./views/BijzondereKenmerkenList";
import Helling from "./components/Helling";
import VlakkenBodemkundigBelangView from "./views/VlakkenBodemkundigBelang";
import SamengesteldeEenheden, {
  SamengesteldeEenhedenItemDetails,
  SamengesteldeEenhedenMain,
} from "./components/SamengesteldeEenheden";
import Begrippen from "./components/Begrippen";
import Literatuur from "./components/Literatuur";
import Login from "./views/Login";
import AdminView from "./views/Admin";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/bodemclassificatie/item"
          element={<MainLayout noScroll />}
        >
          <Route
            path={`:${itemTypes.hoofdklasse}/:${itemTypes.orde}/:${itemTypes.groep}/:${itemTypes.bodemklasse}`}
            element={<MainItemView />}
          />
          <Route
            path={`:${itemTypes.hoofdklasse}/:${itemTypes.orde}/:${itemTypes.groep}`}
            element={<MainItemView />}
          />
          <Route
            path={`:${itemTypes.hoofdklasse}/:${itemTypes.orde}`}
            element={<MainItemView />}
          />
          <Route
            path={`:${itemTypes.hoofdklasse}`}
            element={<MainItemView />}
          />
        </Route>

        <Route
          path="/begrippen"
          element={
            <MainLayout noScroll>
              <Begrippen />
            </MainLayout>
          }
        />

        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomeView />} />

          <Route
            path="/bodemclassificatie"
            element={<BodemclassificatieHomeView />}
          />

          <Route
            path={`bodemklasse/:${itemTypes.bodemklasse}`}
            element={<Bodemklasse />}
          ></Route>

          <Route path={`bijzondere-kenmerken`}>
            <Route index element={<BijzondereKenmerkenView />} />
            <Route
              path={`:type/:code`} //boven or onder
              element={<BijzondereKenmerkenListView />}
            />
            <Route
              path={`:type`} //boven or onder
              element={<BijzondereKenmerkenListView />}
            />
          </Route>

          <Route
            path={`vlakken-bodemkundig-belang`}
            element={<VlakkenBodemkundigBelangView />}
          ></Route>

          <Route path={`helling`} element={<Helling />}></Route>

          <Route
            path="samengestelde-eenheden"
            element={<SamengesteldeEenheden />}
          >
            <Route index element={<SamengesteldeEenhedenMain />} />
            <Route
              path=":code"
              element={<SamengesteldeEenhedenItemDetails />}
            />
          </Route>

          <Route path="literatuur" element={<Literatuur />} />

          <Route path="/login" element={<Login />} />
          <Route
            path="/admin"
            element={
              <RequireAuth>
                <AdminView />
              </RequireAuth>
            }
          />

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth?.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default App;
