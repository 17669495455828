export const vlakkenBodemkundigBelang = [
  {
    code: "Sterk afgegraven terrein",
    omschrijving:
      "Sterk afgegraven gronden, bijv. voor zand-, grind- en kleiwinning en leemputten",
  },
  { code: "Sterk geegaliseerd", omschrijving: "Sterk geëgaliseerde gronden" },
  {
    code: "Sterk opgehoogd terrein",
    omschrijving: "Sterk opgehoogde of opgespoten gronden, zoals gronddepots.",
  },
  { code: "Sterk verwerkt terrein", omschrijving: "Sterk vergraven gronden" },
  {
    code: "Groeve",
    omschrijving:
      "Dagbouw van delfstoffen als (kalk)steen, mergel, leem, zand of grind.",
  },
  {
    code: "Met huisvuil opgehoogde gronden",
    omschrijving: "Al dan niet met grond afgedekte vuilstorten",
  },
  {
    code: "Mijnstort",
    omschrijving:
      "Karakteristieke hoge mijnsteenstorthopen die bestaan uit leisteen uit de vroegere steenkoolmijnen. Veel storthopen zijn door Staatsbosbeheer beplant met bos.",
  },
  {
    code: "Water",
    omschrijving:
      "Open water: zee, rivieren, kanalen, meren, plassen, vennen, enz.",
  },
  {
    code: "Moeras",
    omschrijving:
      "Laaggelegen, begroeide terreinen (bijv. rietlanden) die vanwege een slechte waterafvoer gedeeltelijk langdurig of permanent onder water staan. Ze hebben vaak een drassige, venige bodem en daardoor geen of weinig draagkracht.",
  },
  {
    code: "Terp",
    omschrijving:
      "Oude bewoningsplaatsen, meestal kunstmatig opgehoogd -en soms weer (geheel of gedeeltelijk) afgegraven- zoals terpen, woerden, pollen, kleine donken enz. Het zijn vaak donker gekleurde gronden, o.a. tuineerdgronden, met aardewerkscherven en dikwijls met geelgroene fosfaatvlekken van vergane botten. De plek moet groter zijn dan een boerderij met bijbehorend erf om te worden aangegeven.",
  },
  {
    code: "Bebouwd gebied",
    omschrijving: "Bebouwde kom, wegen, niet gekarteerd",
  },
  {
    code: "Bovenland",
    omschrijving:
      'Smalle stroken onverveend land (ca. 1 m-NAP), meestal tussen twee droogmakerijen en ca. 2-4 m hoger dan deze. Op deze stroken, vaak bestaande uit koopveen- of aarveengronden, is veel bebouwing. Soms is de "cope"-verkaveling van het onvergraven veenland nog te herkennen.',
  },
  {
    code: "Dijk",
    omschrijving:
      "Waterkering: hoge wal langs een rivier of de zee om het achterliggende land te beschermen tegen hoog water.",
  },
];
