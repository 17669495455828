import styled from "styled-components";
import { navigationActive } from "../components/style/color";
import HTMLContent from "./HTMLContent";

export const KeywordSpan = styled.span`
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: ${navigationActive};

  ${(props) => props.busy && "cursor: wait;"}
`;

export const PopupContent = ({ keyword, omschrijving }) => {
  return (
    <div>
      <h4>{keyword}</h4>
      <HTMLContent initialValue={omschrijving} />
    </div>
  );
};
