import styled from "styled-components";
import {
  NavigationGrid,
  NavigationGridItemContainer,
} from "../BodemItem/styled";
import Loader from "../common/Loader";

const KenmerkId = styled.div``;
const KenmerkDefinitie = styled.div`
  & p {
    margin: 0;
  }
`;

const Kenmerk = ({ id, definitie }) => {
  return (
    <NavigationGridItemContainer to={id}>
      <KenmerkId>{id}</KenmerkId>
      <KenmerkDefinitie
        dangerouslySetInnerHTML={{ __html: definitie }}
      ></KenmerkDefinitie>
    </NavigationGridItemContainer>
  );
};

const KenmerkenOverview = ({ kenmerken }) => {
  if (!kenmerken?.length) {
    return <Loader />;
  }
  return (
    <div>
      {/* <p>
        <small>
          <i>Klik op een kenmerk voor een toelichting.</i>
        </small>
      </p> */}
      <NavigationGrid>
        {kenmerken &&
          kenmerken.map((kenmerk) => {
            return <Kenmerk key={kenmerk.id} {...kenmerk} />;
          })}
      </NavigationGrid>
    </div>
  );
};

export default KenmerkenOverview;
