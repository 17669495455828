import { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/Auth";

const Notification = styled.div``;

const Login = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname;

  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (!auth.user) {
      auth.login().catch((err) => {
        console.warn(err);
        setNotification({
          type: "error",
          message: <p>Fout bij het inloggen!.</p>,
        });
      });
    }
  }, [auth]);

  useEffect(() => {
    if (auth.user) {
      if (from) {
        navigate(from, { replace: true });
      } else {
        setNotification({
          type: "info",
          message: <p>Je bent ingelogd.</p>,
        });
      }
    }
  }, [auth.user, from, navigate]);

  return (
    <div>
      {notification?.message && (
        <Notification
        // type={notification?.type}
        // onClose={() => {
        //   setNotification(null);
        // }}
        >
          {notification?.message}
        </Notification>
      )}

      {from && <p>You must log in to view the page at {from}</p>}
    </div>
  );
};
export default Login;
