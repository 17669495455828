import styled, { css } from "styled-components";
import {
  navigation,
  navigationActive,
  navigationHover,
  navigationInactive,
} from "./style/color";

const arrowSize = ({ $height }) => $height / 2;
const entityPadding = ({ $height }) => $height / 4;
const color = ({ $inactive, $active }) => {
  if ($inactive) return navigationInactive;
  if ($active) return navigationActive;
  return navigation;
};

const getLeftPadding = (props) =>
  props.leftPadding || props.leftPadding === 0
    ? props.leftPadding
    : arrowSize(props);

const arrowStyle = css`
  content: "";
  position: absolute;
  top: 0;
  border: 0 solid ${color};
  border-width: ${arrowSize}px;
  width: 0;
  height: 0;
  z-index: -1;
`;

const straightArrowStyle = css`
  content: "";
  position: absolute;
  top: 0;
  background: ${color};
  border: none;
  width: ${getLeftPadding}px;
  height: ${({ $height }) => $height}px;
  z-index: -1;
`;

const hoverStyle = css`
  background: ${navigationHover};

  &:before {
    border-color: ${navigationHover};
    border-left-color: transparent;
    ${(props) => props.startStraight && `background-color: ${navigationHover}`};
  }

  &:after {
    border-left-color: ${navigationHover};
  }
`;

const StyledEntityElement = styled.div`
  background: ${color};
  font-size: ${(props) => (props.height < 30 ? 14 : 16)}px;
  color: white;
  position: relative;
  height: ${({ $height }) => $height}px;
  display: flex;
  align-items: center;
  padding-left: ${entityPadding}px;
  padding-right: ${entityPadding}px;
  cursor: ${(props) => (props.$active ? "default" : "pointer")};
  margin-left: ${getLeftPadding}px;
  white-space: nowrap;

  &:before {
    ${(props) => (props.startStraight ? straightArrowStyle : arrowStyle)};
    left: -${getLeftPadding}px;
    border-left-color: transparent;
  }

  &:after {
    ${arrowStyle}
    left: 100%;
    border-color: transparent;
    border-left-color: ${color};
  }

  &:hover {
    ${(props) => !props.$active && hoverStyle}
  }
`;

const TextArrow = ({
  active = false,
  inActive = false,
  height = 40,
  onClick,
  children,
  startStraight = false,
  leftPadding,
}) => {
  return (
    <StyledEntityElement
      $height={height}
      $inactive={inActive}
      $active={active}
      onClick={onClick}
      startStraight={startStraight}
      leftPadding={leftPadding}
    >
      {children}
    </StyledEntityElement>
  );
};

export default TextArrow;
