export const primary = "rgb(34, 181, 115)";
export const primary50 = "rgba(34, 181, 115, 0.5)";

// EntityNavigator / TextArrows:
export const navigation = "#4da6ff";
// export const navigationActive = "#0066cc";
export const navigationActive = navigation;
export const navigationHover = "#0066cc";
export const navigationInactive = "#c6d9ec";

export const navigationRow = "#cce6ff";
export const navigationRowHover = navigation;
export const navigationRowText = "#004080";
export const navigationRowHoverText = "#003366";

// BreadCrumbs:
export const navigationText = navigation;
export const navigationTextHover = navigationHover;
