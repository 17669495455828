import { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import useDimensions from "../hooks/useDimensions";

const Handle = styled.div`
  height: 4px;
  width: 100%;
  cursor: ns-resize;
  border-top: 1px solid #ccc;
  position: sticky;
  top: 1px;
`;

const isEmpty = (val) => val === null || typeof val === "undefined";

export const Foot = styled.div.attrs((props) => {
  let style = {};
  if (isEmpty(props.$height)) {
    style.maxHeight = "40vh";
  } else {
    style.height = props.$height;
  }
  return {
    style,
  };
})`
  flex-shrink: 0;
  overflow-y: auto;
  position: relative;
`;

const ResizableFooter = ({ children, ...props }) => {
  // const [isDragging, setIsDragging] = useState(false);
  // const isDragging = !isEmpty(startPositionRef.current);

  const startPositionRef = useRef(null);

  const [userHeight, setUserHeight] = useState();
  const userHeightRef = useRef();

  const [footerHeightDelta, setFooterHeightDelta] = useState(0);
  const footerHeightDeltaRef = useRef(0);

  const contentContainerRef = useRef();
  const [contentWidth, contentHeight] = useDimensions(contentContainerRef);

  const mainContainerRef = useRef();
  const [mainWidth, mainHeight] = useDimensions(mainContainerRef);
  const [maxHeight, setMaxHeight] = useState(Infinity);
  const minHeight = 32;

  // init
  useEffect(() => {
    if (!isEmpty(contentHeight)) {
      setMaxHeight(contentHeight + 24);
      // reset when content is changed:
      setUserHeight(null);
    }
  }, [contentHeight]);

  // start dragging
  const onMouseDown = useCallback(
    (ev) => {
      if (!userHeightRef.current) {
        // init:
        userHeightRef.current = Math.min(
          Math.max(mainHeight, minHeight),
          maxHeight
        );
        setUserHeight(userHeightRef.current);
      }
      // setIsDragging(true);
      startPositionRef.current = ev.clientY;
    },
    [mainHeight, maxHeight]
  );

  const onTouchStart = useCallback((ev) => {
    // setIsDragging(true);
    startPositionRef.current = ev.touches[0].clientY;
  }, []);

  // drag
  const onMove = useCallback(
    (clientY) => {
      // if (isDragging)
      if (!isEmpty(startPositionRef.current)) {
        let delta = startPositionRef.current - clientY;
        if (userHeightRef.current + delta > maxHeight) {
          delta = maxHeight - userHeightRef.current;
        }
        if (userHeightRef.current + delta < minHeight) {
          delta = minHeight - userHeightRef.current;
        }
        footerHeightDeltaRef.current = delta;
        setFooterHeightDelta(footerHeightDeltaRef.current);
      }
    },
    [maxHeight]
  );

  const onMouseMove = useCallback(
    (ev) => {
      ev.preventDefault();
      onMove(ev.clientY);
    },
    [onMove]
  );

  const onTouchMove = useCallback(
    (ev) => {
      onMove(ev.touches[0].clientY);
    },
    [onMove]
  );

  // stop dragging
  const onMouseUp = useCallback(() => {
    // if (isDragging)
    if (!isEmpty(startPositionRef.current)) {
      // setIsDragging(false);
      startPositionRef.current = null;
      userHeightRef.current =
        userHeightRef.current + footerHeightDeltaRef.current;
      setUserHeight(userHeightRef.current);
      footerHeightDeltaRef.current = 0;
      setFooterHeightDelta(footerHeightDeltaRef.current);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseMove, onTouchMove, onMouseUp]);

  const showHandle = !!children;
  return (
    <Foot
      $height={isEmpty(userHeight) ? null : userHeight + footerHeightDelta}
      ref={mainContainerRef}
      {...props}
    >
      {showHandle && (
        <Handle
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onTouchEnd={onMouseUp}
        />
      )}
      <div ref={contentContainerRef}>{children}</div>
    </Foot>
  );
};
export default ResizableFooter;
