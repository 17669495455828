import { fetchEndpoint } from "./legendData";

/**
 * @typedef {Object} TLiteratuur
 * @property {string} literatuur_id
 * @property {string} omschrijving - html
 * @property {string} link - link to external source (url)
 *
 * @returns {Promise<TLiteratuur[]>}
 */
export function getLiteratuur() {
  return fetchEndpoint("/json/literatuur");
}
