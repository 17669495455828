import { createContext, useContext, useState, useEffect } from "react";
import {
  login as loginService,
  logout as logoutService,
  checkLogin,
  changePassword as changePasswordService,
} from "../services/auth";

/**
 * @typedef {Object} TUser
 * @property {string} name
 * @property {number} id
 * @property {boolean} adminMode
 *
 * @typedef TAuthContext
 * @property {TUser} user
 * @property {function} login
 * @property {function} logout
 * @property {function} changePassword
 * @property {function} verify
 * @property {function} toggleAdminMode
 */

/** @type {import('react').Context<TAuthContext>} */
let AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkLogin()
      .then((result) => {
        if (result) {
          setUser({ ...result, adminMode: true });
        } else {
          setUser(null);
        }
      })
      .catch(console.warn);
  }, []);

  // const login = (username, password) => {
  const login = () => {
    return new Promise((resolve, reject) => {
      loginService()
        .then((loginResult) => {
          const newUser = { ...loginResult, adminMode: true };
          setUser(newUser);
          resolve(newUser);
        })
        .catch((loginError) => {
          setUser(null);
          reject(loginError);
        });
    });
  };

  const logout = () => {
    return new Promise((resolve, reject) => {
      logoutService()
        .then(() => {
          setUser(null);
          resolve();
        })
        .catch(reject);
    });
  };

  const changePassword = (newpassword) => {
    return new Promise((resolve, reject) => {
      changePasswordService(newpassword)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  };

  const verify = () => {
    checkLogin()
      .then((result) => {
        if (result) {
          setUser({ ...result, adminMode: true });
        } else {
          setUser(null);
        }
      })
      .catch(console.warn);
  };

  const toggleAdminMode = () => {
    setUser((prev) => ({ ...prev, adminMode: !prev.adminMode }));
  };

  const value = {
    user,
    login,
    logout,
    changePassword,
    verify,
    toggleAdminMode,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
