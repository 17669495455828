import { fetchEndpoint } from "./legendData";

/**
 * correspondents with server -> SaveContentResource -> ALLOWED_TYPES
 */
export const dataTypes = {
  bodemhoofdklasse: "bodemhoofdklasse",
  bodemorde: "bodemorde",
  bodemgroep: "bodemgroep",
  bodemklasse: "bodemklasse",
  kenmerken_boven: "kenm_boven",
  kenmerken_onder: "kenm_onder",
  begrippen: "begrippen",
};

/**
 * correspondents with server -> SaveContentResource -> ALLOWED_FIELDS
 */
export const dataFields = {
  definitie: "definitie",
  omschrijving: "omschrijving",
  naam: "naam",
  verspreiding: "verspreiding",
};

const requestOptions = {
  method: "POST",
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/x-www-form-urlencoded",
  },
  // body: JSON.stringify({a: 7, str: 'Some string: &=&'})
};

// function fetchEndpoint(endpoint, initData, options = requestOptions) {
//     return new Promise((resolve, reject) => {
//       fetch(`${baseUrl}${endpoint}`, options)
//         .then((response) => response.json())
//         .then((result) => {
//           let data;
//           if (result?.success) {
//             data = result.items || result.data;
//             if (initData) {
//               resolve(initData(data));
//             } else {
//               resolve(data);
//             }
//           } else {
//             reject(new Error(`Call to endpoint ${endpoint} failed.`));
//           }
//         })
//         .catch((fetchError) => {
//           reject(fetchError);
//         });
//     });
//   }

export function saveTest(type, id, value, field) {
  const body = JSON.stringify({ [field]: value });
  return fetchEndpoint(`/save-content/${type}?$par1=${id}`, undefined, {
    ...requestOptions,
    body,
  });
}

export function saveContent(type, id, value, field) {
  // const body = JSON.stringify({ [field]: value });
  const body = new URLSearchParams();
  body.append("content", value);

  return fetchEndpoint(`/save-content/${type}/${field}?id=${id}`, undefined, {
    ...requestOptions,
    body,
  });
}

// var myHeaders = new Headers();
// myHeaders.append("Authorization", "Basic cm9ubmllOm9uYmVrZW5k");
// myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
