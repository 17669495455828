import PropTypes from "prop-types";
import { slugify } from "../helpers/string";
import { isEmpty } from "lodash";

export const itemTypes = {
  hoofdklasse: "hoofdklasse",
  orde: "orde",
  groep: "groep",
  bodemklasse: "bodemklasse",
};

export const emptySlug = "-";

export const baseUrl = "/soilmaplegendserver";
export const requestOptions = {
  method: "GET",
  redirect: "follow",
};

export const itemProps = PropTypes.shape({
  id: PropTypes.string, // all
  label: PropTypes.string, // all
  code: PropTypes.string, // all
  definitie: PropTypes.string, // all
  omschrijving: PropTypes.string, //
  verspreiding: PropTypes.string, //
  // hoofdklasse: hoofdKlasseProps, // none?
});

export function fetchEndpoint(endpoint, initData, options = requestOptions) {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}${endpoint}`, options)
      .then((response) => response.json())
      .then((result) => {
        let data;
        if (result?.success) {
          data = result.items || result.data;
          if (initData) {
            resolve(initData(data));
          } else {
            resolve(data);
          }
        } else {
          reject(new Error(`Call to endpoint ${endpoint} failed.`));
        }
      })
      .catch((fetchError) => {
        reject(fetchError);
      });
  });
}

function initHoofdklasse(raw) {
  if (isEmpty(raw)) {
    return raw;
  }
  const id = raw.leg_bodemhoofdklasse_id;
  const label = raw.naam;
  return { ...raw, id, label, slug: slugify(label || id) };
}

function initOrde(raw) {
  const id = raw.leg_bodemorde_id;
  const label = raw.naam;
  return {
    ...raw,
    id,
    label,
    slug: slugify(label || id),
  };
}

function initGroep(raw) {
  const id = raw.leg_bodemgroep_id;
  const label = raw.naam;
  return {
    ...raw,
    id,
    label,
    slug: slugify(label || id),
  };
}

function initBodemklasse(raw) {
  const id = raw.leg_bodemklasse_id;
  const label = raw.definitie;
  return {
    ...raw,
    label,
    id: raw.leg_bodemklasse_id,
    slug: slugify(id),
  };
}

export function getBodemklasseSlug(id) {
  return new Promise((resolve, reject) => {
    fetchEndpoint(`/json/bodemklasse_lookup?par1=${id}`, (data) => {
      if (data.length < 0) {
        return null;
      }
      const [bodemklasse] = data;
      return `/bodemclassificatie/item/${slugify(
        bodemklasse.hoofdklasse
      )}/${slugify(bodemklasse.orde)}/${slugify(bodemklasse.groep)}/${slugify(
        bodemklasse.bodemklasse
      )}`;
    })
      .then((slug) => {
        if (!slug) {
          reject(new Error(`Bodemklasse ${id} not found`));
        } else {
          resolve(slug);
        }
      })
      .catch(reject);
  });
}

let hoofdklassenCache;
export function getAllHoofdklassen() {
  if (hoofdklassenCache) {
    return new Promise((resolve) => {
      resolve(hoofdklassenCache);
    });
  }

  return fetchEndpoint("/json/bodemhoofdklasse_summary", (data) => {
    hoofdklassenCache = data.map(initHoofdklasse);
    return hoofdklassenCache;
  });
}

export function getAllOrdes(bodemhoofdklasse_id) {
  return fetchEndpoint(
    `/json/bodemorde_summary${
      bodemhoofdklasse_id ? `?par1=${bodemhoofdklasse_id}` : ""
    }`,
    (d) => {
      return d.map(initOrde);
    }
  );
}

export function getAllGroepen(bodemorde_id) {
  if (!bodemorde_id) {
    throw new Error("Invalid function call");
  }
  return fetchEndpoint(`/json/bodemgroep_summary?par1=${bodemorde_id}`, (d) => {
    return d.map(initGroep);
  });
}

export function getAllBodemklassen(bodemgroep_id) {
  if (!bodemgroep_id) {
    throw new Error("Invalid function call");
  }
  return fetchEndpoint(
    `/json/bodemklasse_summary?par1=${bodemgroep_id}`,
    (d) => {
      return d.map(initBodemklasse);
    }
  );
}

export function getHoofdklasse(id) {
  return fetchEndpoint(
    `/item/bodemhoofdklasse/${encodeURIComponent(id)}`,
    initHoofdklasse
  );
}

export function getHoofdklasseSummaryFromSlug(slug) {
  return new Promise((resolve, reject) => {
    getAllHoofdklassen()
      .then((allHoofdklassen) => {
        const match = allHoofdklassen.find((h) => h.slug === slug);
        if (match) {
          resolve(match);
        } else {
          reject(new Error(`No Hoofdklasse with slug '${slug}' found`));
        }
      })
      .catch(reject);
  });
}

export function getHoofdklasseFromSlug(slug) {
  return new Promise(async (resolve, reject) => {
    const summary = await getHoofdklasseSummaryFromSlug(slug);
    if (summary) {
      getHoofdklasse(summary.id).then(resolve).catch(reject);
    } else {
      reject(new Error("Hoofdklasse not found"));
    }
  });
}

export const hoofdKlasseProps = PropTypes.shape({
  id: PropTypes.string,
  code: PropTypes.string,
  definitie: PropTypes.string,
});

export function getOrde(id) {
  return fetchEndpoint(`/item/bodemorde/${encodeURIComponent(id)}`, initOrde);
}

export const ordeProps = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  code: PropTypes.string,
  definitie: PropTypes.string,
  hoofdklasse: hoofdKlasseProps,
});

export function getOrdeFromSlug(bodemhoofdklasse_id, slug) {
  return new Promise(async (resolve, reject) => {
    const summary = await getOrdeSummaryFromSlug(bodemhoofdklasse_id, slug);
    if (summary) {
      getOrde(summary.id).then(resolve).catch(reject);
    } else {
      reject(new Error("Orde not found"));
    }
  });
}

export function getOrdeSummaryFromSlug(bodemhoofdklasse_id, slug) {
  return new Promise((resolve, reject) => {
    getAllOrdes(bodemhoofdklasse_id)
      .then((allOrdes) => {
        const match = allOrdes.find((d) => d.slug === slug);
        if (match) {
          resolve(match);
        } else {
          reject(new Error(`No orde with slug '${slug}' found`));
        }
      })
      .catch(reject);
  });
}

export function getGroepSummaryFromSlug(bodemorde_id, slug) {
  return new Promise((resolve, reject) => {
    getAllGroepen(bodemorde_id)
      .then((allGroepen) => {
        const match = allGroepen.find((d) => d.slug === slug);
        if (match) {
          resolve(match);
        } else {
          reject(new Error(`No groep with slug '${slug}' found`));
        }
      })
      .catch(reject);
  });
}

export function getGroep(id) {
  return fetchEndpoint(`/item/bodemgroep/${encodeURIComponent(id)}`, initGroep);
}

export function getGroepFromSlug(bodemorde_id, slug) {
  return new Promise(async (resolve, reject) => {
    const summary = await getGroepSummaryFromSlug(bodemorde_id, slug);
    if (summary) {
      getGroep(summary.id).then(resolve).catch(reject);
    } else {
      reject(new Error("Groep not found"));
    }
  });
}

export const groepProps = PropTypes.shape({
  id: PropTypes.string,
  code: PropTypes.string,
  definitie: PropTypes.string,
});

export function getBodemklasseSummaryFromSlug(bodemgroep_id, slug) {
  return new Promise((resolve, reject) => {
    getAllBodemklassen(bodemgroep_id)
      .then((allKlassen) => {
        const match = allKlassen.find((d) => d.slug === slug);
        if (match) {
          resolve(match);
        } else {
          reject(new Error(`No bodemklasse with slug '${slug}' found`));
        }
      })
      .catch(reject);
  });
}

export function getBodemklasse(id) {
  return fetchEndpoint(
    // `/json/bodemklasse?par1=${encodeURIComponent(id)}`,
    `/item/bodemklasse/${encodeURIComponent(id)}`,
    initBodemklasse
  );
}

export function getBodemklasseFromSlug(bodemgroep_id, slug) {
  return new Promise(async (resolve, reject) => {
    const summary = await getBodemklasseSummaryFromSlug(bodemgroep_id, slug);
    if (summary) {
      getBodemklasse(summary.id).then(resolve).catch(reject);
    } else {
      reject(new Error("Bodemklasse not found"));
    }
  });
}

export const bodemKlasseProps = PropTypes.shape({
  id: PropTypes.string,
  code: PropTypes.string,
  definitie: PropTypes.string,
});

export const itemSummaryProps = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  code: PropTypes.string,
});

/**
 * @typedef {Object} TItemsFromSlug
 * @property {Object} hoofdklasseSummary
 * @property {Object} ordeSummary
 * @property {Object} groepSummary
 * @property {Object} hoofdklasse
 * @property {Object} orde
 * @property {Object} groep
 * @property {Object} bodemklasse
 *
 * @param {*} hoofdklasseSlug
 * @param {*} ordeSlug
 * @param {*} groepSlug
 * @param {*} bodemklasseSlug
 * @returns {Promise<TItemsFromSlug>}
 */
export function getItemsFromSlug(
  hoofdklasseSlug,
  ordeSlug,
  groepSlug,
  bodemklasseSlug
) {
  return new Promise(async (resolve, reject) => {
    if (!hoofdklasseSlug) {
      resolve({});
      // reject(new Error("Geen items gevonden"));
      return;
    }
    try {
      const hoofdklasse = await getHoofdklasseFromSlug(hoofdklasseSlug);
      if (isEmpty(hoofdklasse)) {
        reject(new Error("Geen items gevonden"));
        return;
      }
      if (!ordeSlug) {
        resolve({
          hoofdklasse,
        });
        return;
      }

      let orde;
      if (ordeSlug === emptySlug) {
        orde = {
          id: hoofdklasse.leg_bodemhoofdklasse_id,
          leg_bodemorde_id: hoofdklasse.leg_bodemhoofdklasse_id,
          empty: true,
        };
      } else {
        orde = await getOrdeFromSlug(
          hoofdklasse.leg_bodemhoofdklasse_id,
          ordeSlug
        );
        if (!orde) {
          reject(new Error("Geen items gevonden"));
          return;
        }
        if (!groepSlug) {
          resolve({
            hoofdklasse,
            orde,
          });
          return;
        }
      }

      let groep;
      if (groepSlug === emptySlug) {
        groep = {
          id: orde.leg_bodemorde_id,
          leg_bodemgroep_id: orde.leg_bodemorde_id,
          empty: true,
        };
      } else {
        groep = await getGroepFromSlug(orde.leg_bodemorde_id, groepSlug);
        if (!groep) {
          reject(new Error("Geen items gevonden"));
          return;
        }
        if (!bodemklasseSlug) {
          resolve({
            hoofdklasse,
            orde,
            groep,
          });
          return;
        }
      }

      const bodemklasse = await getBodemklasseFromSlug(
        groep.leg_bodemgroep_id,
        bodemklasseSlug
      );
      if (!bodemklasse) {
        reject(new Error("Geen items gevonden"));
        return;
      }
      resolve({
        hoofdklasse,
        orde,
        groep,
        bodemklasse,
      });
    } catch (error) {
      reject(error);
    }
  });
}

const itemTypeArray = [
  itemTypes.hoofdklasse,
  itemTypes.orde,
  itemTypes.groep,
  itemTypes.bodemklasse,
];
const childLevelGetters = [
  getAllOrdes,
  getAllGroepen,
  getAllBodemklassen,
  null,
];

async function getChildren(parentLevel, parentId, slugPrefix = "") {
  if (!parentId) {
    throw new Error("parentId not given");
  }
  const fetchFunction = childLevelGetters[parentLevel];
  if (!fetchFunction) {
    return [];
  }
  const directChildren = await fetchFunction(parentId);

  const isEmptyChild = (child) => !child.naam && child.id === parentId;

  let lowerChildren = await directChildren
    .filter(isEmptyChild)
    .reduce(async (childArray, emptyChild) => {
      const subChildren = await getChildren(
        parentLevel + 1,
        emptyChild.id,
        `${slugPrefix}${emptySlug}/`
      );
      return [...childArray, ...subChildren];
    }, []);

  return [
    ...directChildren
      .filter((child) => !isEmptyChild(child))
      .map((child) => ({
        ...child,
        itemType: itemTypeArray[parentLevel + 1],
        slug: `${slugPrefix}${child.slug}`,
      })),
    ...lowerChildren,
  ];
}

export function getChildItems(parentType, parentId) {
  return new Promise((resolve, reject) => {
    const parentLevel = itemTypeArray.indexOf(parentType);
    getChildren(parentLevel, parentId).then(resolve).catch(reject);
  });
}

export function getNavItems(items) {
  if (!items) return [];
  const { hoofdklasse, orde, groep, bodemklasse } = items;
  const navItems = [];

  const getNavItem = (item, type) => ({
    id: item.id,
    label: item.naam || item.id,
    itemType: type,
    slug: item.slug,
    item,
  });

  if (hoofdklasse) {
    navItems.push(getNavItem(hoofdklasse, itemTypes.hoofdklasse));
  }

  if (orde && !orde.empty) {
    navItems.push(getNavItem(orde, itemTypes.orde));
  }

  if (groep && !groep.empty) {
    navItems.push(getNavItem(groep, itemTypes.groep));
  }

  if (bodemklasse) {
    navItems.push({
      ...getNavItem(bodemklasse, itemTypes.bodemklasse),
      label: bodemklasse.id,
    });
  }
  return navItems;
}

export function getKenmerken(id) {
  return fetchEndpoint(`/json/extra_kenmerken?par1=${encodeURIComponent(id)}`);
}

/**
 * @typedef {Object} TBijzonderKenmerk
 * @property {string} id
 * @property {string} definitie
 * @property {string} omschrijving
 */
export function getBijzondereKenmerkenBoven() {
  return fetchEndpoint("/json/kenmerken_boven");
}

export function getBijzondereKenmerkenOnder() {
  return fetchEndpoint("/json/kenmerken_onder");
}

export function getSamengesteldeEenheden() {
  return fetchEndpoint("/json/sameng_leg_eenheden_summary", (data) =>
    data.map((d) => ({
      id: d.leg_sameng_leg_eenheden_id,
      definitie: d.definitie,
    }))
  );
}

export function getSamengesteldeEenheid(code) {
  if (!code) {
    throw new Error("No code");
  }
  return fetchEndpoint(`/json/sameng_leg_eenheden?par1=${code}`, (data) => {
    if (!data || data.length < 1) {
      return null;
    }
    const item = data[0];
    return { ...item, id: code };
  });
}
