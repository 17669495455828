import styled from "styled-components";
import { useOutletContext } from "react-router-dom";
import ItemList from "./ItemList";
import SamengesteldeEenhedenItemDetails from "./ItemDetails";
import useAutoKeywords from "../../hooks/useAutoKeywords";

export { SamengesteldeEenhedenItemDetails };

const ListItem = styled.li`
  margin-bottom: 1em;
`;

const SamengesteldeEenhedenMain = () => {
  const { eenheden } = useOutletContext();
  const { Popup, AutoKeywords, autoKeywordsProps, popupProps } =
    useAutoKeywords();

  return (
    <div>
      <AutoKeywords {...autoKeywordsProps}>
        <p>
          Plaatselijk kan de bodem een zo ingewikkeld patroon vormen, dat een
          kaartvlak op de bodemkaart niet meer aangeduid kan worden met een
          bodemklasse bestaande uit een enkelvoudige legenda-eenheid. Er wordt
          dan gekozen voor een bodemklasse met een samengestelde
          legenda-eenheid. Samengestelde legenda-eenheden bestaan uit twee of
          meer enkelvoudige legenda-eenheden:
        </p>

        <ul>
          <ListItem>
            Wanneer samengestelde legenda-eenheden bestaan uit twee of drie
            enkelvoudige legenda-eenheden krijgen ze de bodemcode van de
            samenstellende delen. Bij de codering worden de twee of drie
            enkelvoudige legenda-eenheden apart benoemd via de attributen
            Bodemcode1, Bodemcode2 en Bodemcode3 waarbij de rangorde níet wordt
            bepaald door hun onderlinge oppervlakte of belangrijkheid maar door
            hun volgorde in de legenda.
          </ListItem>
          <ListItem>
            Samengestelde legenda-eenheden kunnen ook uit vele enkelvoudige
            legenda-eenheden bestaan waarbij het niet meer loont om in de
            bodemcode de onderliggende enkelvoudige legenda-eenheden apart te
            beschrijven. Bij de beschrijving is getracht een globale
            samenstelling te geven door het vermelden van enkele belangrijke
            componenten. Deze complexe kaartvlakken krijgen een aparte bodemcode
            die altijd begint met een A van Associatie van vele enkelvoudige
            legenda-eenheden, gevolgd door een code van een nadere aanduiding:
          </ListItem>
        </ul>
      </AutoKeywords>
      <Popup {...popupProps} />
      <ItemList items={eenheden} />
    </div>
  );
};
export default SamengesteldeEenhedenMain;
