import { ChildrenContainer, NoMarkupLink } from "./styled";
import TextArrow from "../TextArrow";

const ArrowList = ({
  items,
  navItems,
  linkBase,
  setActiveNavItem,
  arrowStartStraight = false,
}) => {
  return (
    <ChildrenContainer>
      {items.map((child) => {
        const relatedNavItem = navItems?.find(
          (navItem) =>
            child.itemType === navItem.itemType && child.id === navItem.id
        );
        if (relatedNavItem) {
          return (
            <TextArrow
              height={25}
              startStraight={arrowStartStraight}
              active
              key={child.id}
              onClick={() => setActiveNavItem(relatedNavItem)}
            >
              ({child.id}) {child.label}
            </TextArrow>
          );
        }
        return (
          <NoMarkupLink key={child.id} to={`${linkBase}${child.slug}`}>
            <TextArrow height={25} startStraight={arrowStartStraight}>
              ({child.id}) {child.label}
            </TextArrow>
          </NoMarkupLink>
        );
      })}
    </ChildrenContainer>
  );
};
export default ArrowList;
