import { useState } from "react";
import HTMLContent from "../HTMLContent";
import { useAuth } from "../../context/Auth";
import { saveContent, dataFields, dataTypes } from "../../services/saveContent";
import Notification from "../Notification";

const SingleBegrip = ({ begripId, begrip = {}, onEdit }) => {
  const auth = useAuth();
  const { user } = auth;
  const adminMode = user?.adminMode;
  const [busySaving, setBusySaving] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const omschrijving = begrip?.omschrijving;

  const saveOmschrijving = (content) => {
    setBusySaving(true);
    saveContent(dataTypes.begrippen, begripId, content, dataFields.omschrijving)
      .then((result) => {
        // console.log("saveContent result:", field, result);
        // setStoredData((prev) => ({ ...prev, [field]: content }));
        setSaveError(false);
      })
      .catch((serviceError) => {
        setSaveError(serviceError.message || true);
        console.error(serviceError);
      })
      .finally(() => {
        setBusySaving(false);
        onEdit?.(content);
      });
  };

  return (
    <div>
      <Notification show={saveError} type="error">
        Er ging helaas iets mis bij het opslaan van de gegevens.
        {Boolean(saveError && saveError !== true) && (
          <>
            <br />
            <code>{saveError}</code>
          </>
        )}
      </Notification>
      <HTMLContent
        initialValue={omschrijving}
        onSave={saveOmschrijving}
        editable={adminMode}
      />
    </div>
  );
};
export default SingleBegrip;
