import { useEffect, useState } from "react";
import { getLiteratuur } from "../services/literatuur";

const Literatuur = () => {
  const [literatuur, setliteratuur] = useState([]);

  useEffect(() => {
    getLiteratuur().then((data) => setliteratuur(data));
  }, []);

  return (
    <div>
      {literatuur.map((literatuurItem) => {
        const content = literatuurItem.omschrijving;
        return (
          <p key={literatuurItem.literatuur_id}>
            {literatuurItem.link ? (
              <a
                href={literatuurItem.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                {content}
              </a>
            ) : (
              content
            )}
          </p>
        );
      })}
    </div>
  );
};

export default Literatuur;
