import { useState } from "react";
import styled from "styled-components";
import TinyMCEEditor from "./TinyMCEEditor";
import useAutoKeywords from "../../hooks/useAutoKeywords";

/**
 * styling in public\tinymce.css
 * that way the styling is also applied while editing
 */
const MainContainer = styled.div``;

// const FieldLabel = styled.h5`
//   font-weight: normal;
//   font-style: italic;
// `;

const EditButton = styled.div`
  cursor: pointer;
  user-select: none;
  background: #ffffff80;
  padding: 0.1em 0.4em;
  box-shadow: 0px 1px 3px 0px #000000a6;
  font-weight: 500;
  border-radius: 4px;
  color: #0000c0;
  display: inline-block;
`;

const ViewerContainer = styled.div.attrs({ className: "tinymce-content" })`
  /* &::after {
    content: "";
    clear: both;
    display: table;
  } */
`;

const TinyMCEContent = ({
  initialValue,
  onSave,
  editable = false,
  label,
  singleLineMarkup = false,
  ...props
}) => {
  const { AutoKeywords, Popup, autoKeywordsProps, popupProps } =
    useAutoKeywords();

  const [editMode, setEditMode] = useState(false);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = (...args) => {
    Promise.resolve(onSave(...args)).then(() => {
      setEditMode(false);
    });
  };

  return (
    <MainContainer {...props}>
      {/* <FieldLabel>{label}:</FieldLabel> */}

      {editMode && (
        <TinyMCEEditor
          initialValue={initialValue}
          onSave={handleSaveClick}
          onCancel={() => setEditMode(false)}
          singleLineMarkup={singleLineMarkup}
        />
      )}

      {!editMode && (
        <ViewerContainer>
          {editable && <EditButton onClick={handleEditClick}>edit</EditButton>}
          <AutoKeywords {...autoKeywordsProps}>
            <div
              dangerouslySetInnerHTML={{ __html: initialValue || "&nbsp;" }}
            />
          </AutoKeywords>
        </ViewerContainer>
      )}
      <Popup {...popupProps} />
    </MainContainer>
  );
};

export default TinyMCEContent;
