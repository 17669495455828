import styled from "styled-components";
import TextArrow from "./TextArrow";
import PropTypes from "prop-types";
import { itemTypes } from "../services/legendData";

const MainContainer = styled.nav`
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
`;

const EntityNavigator = ({
  items = [],
  activeNavItem,
  height = 40,
  ...props
}) => {
  let nextInActive = false;

  return (
    <MainContainer $height={height} {...props}>
      {items.map((item, itemIndex) => {
        const inActive = nextInActive;

        const isActiveItem =
          activeNavItem &&
          item.itemType === activeNavItem.itemType &&
          item.id === activeNavItem.id;

        if (isActiveItem) {
          nextInActive = true;
        }

        return (
          <TextArrow
            key={`${item.itemType}--${item.id}`}
            height={height}
            inActive={
              typeof activeNavItem !== "undefined" &&
              activeNavItem !== null &&
              inActive
            }
            active={
              typeof activeNavItem !== "undefined" &&
              activeNavItem !== null &&
              isActiveItem
            }
            startStraight={itemIndex === 0}
            leftPadding={itemIndex === 0 ? 4 : null}
            onClick={item.onClick}
          >
            {item.label}
          </TextArrow>
        );
      })}
    </MainContainer>
  );
};

const navItemPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  itemType: PropTypes.oneOf(["root", undefined, ...Object.values(itemTypes)]), // ["root"]
  onClick: PropTypes.func,
});

EntityNavigator.propTypes = {
  items: PropTypes.arrayOf(navItemPropTypes),
  activeNavItem: navItemPropTypes,
  height: PropTypes.number,
};

export default EntityNavigator;
