import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../context/Auth";

const HeaderContainer = styled.div`
  background: radial-gradient(
    circle,
    rgba(34, 181, 115, 0.7) 0%,
    rgb(34, 181, 115) 100%
  );
  padding: 8px 2% 16px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 32px;
  color: #fff;
`;

const Menu = styled.nav`
  align-self: flex-end;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  & > a {
    display: block;
  }
`;
const MenuLink = styled(NavLink)`
  margin-right: 1.3em;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  white-space: nowrap;

  &.active {
    color: #000;
  }

  &:hover {
    color: #ccc;
  }
`;

const AdminToggle = styled.div`
  margin-right: 16px;
  display: inline-block;
`;

const UserBlock = () => {
  const auth = useAuth();
  const { user, toggleAdminMode } = auth;
  if (!user) {
    return (
      <div>
        <Link to="/admin">Inloggen</Link>
      </div>
    );
  }
  return (
    <div>
      <AdminToggle>
        <label htmlFor="adminMode">Admin view</label>
        <input
          type="checkbox"
          checked={user.adminMode}
          onChange={toggleAdminMode}
          id="adminMode"
        />
      </AdminToggle>
      <Link to="/admin">{user.name}</Link>
    </div>
  );
};

const Header = () => {
  return (
    <HeaderContainer>
      <FlexRow>
        <Title>Bodemkaartlegenda</Title>
        <UserBlock />
      </FlexRow>
      <Menu>
        <MenuLink to="/">Bodemkaart</MenuLink>
        <MenuLink to="/bodemclassificatie">Bodemclassificatie</MenuLink>
        <MenuLink to="/bijzondere-kenmerken">Bijzondere kenmerken</MenuLink>
        <MenuLink to="/helling">Helling</MenuLink>
        <MenuLink to="/vlakken-bodemkundig-belang">
          Vlakken van bodemkundig belang
        </MenuLink>
        <MenuLink to="/samengestelde-eenheden">
          Samengestelde legenda-eenheden
        </MenuLink>
        <MenuLink to="/begrippen">Begrippen</MenuLink>
        <MenuLink to="/literatuur">Literatuur</MenuLink>
      </Menu>
    </HeaderContainer>
  );
};

export default Header;
