import styled from "styled-components";

export const DataTable = styled.table`
  clear: both;
  border-collapse: separate;
  border-spacing: 1em 1em;
`;

export const HeaderCell = styled.th`
  text-align: left;
`;

export const NoWrapCell = styled.td`
  white-space: nowrap;
  vertical-align: top;
`;
export const Row = styled.tr``;

export default DataTable;
