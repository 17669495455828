import User from "../components/User";

const AdminView = () => {
  return (
    <div>
      <h3>Admin</h3>
      <User />
    </div>
  );
};

export default AdminView;
