import styled from "styled-components";
import { useEffect, useState } from "react";
import { getBegrippenList, getBegrip } from "../../services/begrippen";
import SingleBegrip from "./SingleBegrip";
import EntityNavigator from "../EntityNavigator";
import BegrippenMain from "./Main";
import { FlexDiv } from "./styled";

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Begrippen = () => {
  const [begrippen, setBegrippen] = useState([]);
  const [selectedBegrip, setSelectedBegrip] = useState(null);

  useEffect(() => {
    getBegrippenList()
      .then((data) => {
        setBegrippen(data);
      })
      .catch(() => {
        console.error("Fout: Kan begrippenlijst niet ophalen.");
      });
  }, []);

  const selectBegrip = (begrip) => {
    setSelectedBegrip({ begripId: begrip, begrip: null });
    getBegrip(begrip).then((data) => {
      setSelectedBegrip((prev) => ({ ...prev, begrip: data }));
    });
  };
  const closeBegrip = () => {
    setSelectedBegrip(null);
  };

  const navItems = [
    {
      id: "root",
      itemType: "root",
      label: "Begrippen",
      onClick: closeBegrip,
    },
  ];
  if (selectedBegrip) {
    navItems.push({
      id: selectedBegrip.begripId,
      itemType: "begrip",
      label: selectedBegrip.begripId,
    });
  }

  return (
    <MainContainer>
      <EntityNavigator
        style={{ marginBottom: 32 }}
        items={navItems}
        activeNavItem={navItems[navItems.length - 1]}
      />
      {selectedBegrip ? (
        <FlexDiv>
          <SingleBegrip
            begripId={selectedBegrip.begripId}
            begrip={selectedBegrip.begrip}
            close={closeBegrip}
            onEdit={() => {
              selectBegrip(selectedBegrip.begripId);
            }}
          />
        </FlexDiv>
      ) : (
        <BegrippenMain
          begrippen={begrippen}
          selectedBegrip={selectedBegrip}
          selectBegrip={selectBegrip}
        />
      )}
    </MainContainer>
  );
};
export default Begrippen;
