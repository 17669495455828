import styled from "styled-components";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import KenmerkenOverview from "../components/BijzondereKenmerken/KenmerkenOverview";
import KenmerkDetails from "../components/BijzondereKenmerken/KenmerkDetails";
import {
  getBijzondereKenmerkenBoven,
  getBijzondereKenmerkenOnder,
} from "../services/legendData";
import EntityNavigator from "../components/EntityNavigator";
// import ListIcon from "../components/Icons/ListIcon";

const HeaderContainer = styled.div`
  margin-bottom: 2em;
`;
// const HeaderText = styled.h3`
//   margin-bottom: 0.25em;
// `;

const Header = ({ type, code }) => {
  const navigate = useNavigate();

  const items = [
    {
      id: "root",
      // label: <ListIcon />,
      label: "Bijzondere kenmerken",
      itemType: "root",
      onClick: () => {
        navigate(`..`);
      },
    },
  ];

  if (code) {
    items.push({
      id: type,
      label: `${type}laag`,
      onClick: () => {
        navigate(`../${type}`);
      },
    });
    items.push({ id: code, label: code });
  } else {
    items.push({ id: type, label: `${type}laag` });
  }

  return (
    <HeaderContainer>
      <EntityNavigator items={items} activeNavItem={items[items.length - 1]} />
      {/* <HeaderText>{headerText}</HeaderText> */}
    </HeaderContainer>
  );
};

const BijzondereKenmerkenListView = () => {
  let { type, code } = useParams();
  const [kenmerken, setKenmerken] = useState([]);
  const [dirtyState, setDirtyState] = useState(0);

  useEffect(() => {
    setKenmerken([]);
    if (type === "boven") {
      getBijzondereKenmerkenBoven()
        .then((result) => {
          setKenmerken(result);
        })
        .catch(console.error);
    }
    if (type === "onder") {
      getBijzondereKenmerkenOnder().then((result) => {
        setKenmerken(result);
      });
    }
  }, [type, dirtyState]);

  const selectedKenmerk = code
    ? kenmerken.find((kenmerk) => kenmerk.id === code)
    : null;
  return (
    <>
      <Header type={type} code={code} />
      {!selectedKenmerk && type === "boven" ? (
        <p>
          Wanneer de toevoeging betrekking heeft op de bovengrond wordt het
          verschijnsel aangegeven met een letter aan de voorzijde van de code
          van de bodemklasse.
        </p>
      ) : null}
      {!selectedKenmerk && type === "onder" ? (
        <p>
          Wanneer de toevoeging betrekking heeft op een verschijnsel onder de
          bouwvoor en meestal dieper dan 40 cm wordt het aangegeven met een
          letter aan de achterzijde van de code van de bodemklasse.
        </p>
      ) : null}
      {!!(code && selectedKenmerk) && (
        <KenmerkDetails
          {...selectedKenmerk}
          type={type}
          onEdit={() => setDirtyState((prev) => prev + 1)}
        />
      )}
      {!!(code && !selectedKenmerk) && <div>Kenmerk {code} niet gevonden</div>}
      {!code && <KenmerkenOverview kenmerken={kenmerken} />}
    </>
  );
};

export default BijzondereKenmerkenListView;
