import styled, { keyframes } from "styled-components";

const Container = styled.div`
  padding-top: 20px;
  display: flex;
  gap: 5px;
`;

const generateBounce = (count) => {
  const kf = `
    0% {
        transform: translate3d(0, 0, 0);
    }
    ${100 / count}% {
        transform: translate3d(0, -10px, 0);
    } 
    ${(2 * 100) / count}% {
        transform: translate3d(0, 0, 0);
    }   
    100% {
        transform: translate3d(0, 0, 0);
    }
`;
  return keyframes`${kf}`;
};

const Ball = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  animation: ${(props) => generateBounce(props.count)}
    ${(props) => (props.count + 1) * 0.3}s;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: ${(props) => props.index * 0.3}s;
`;

const Loader = ({ count = 5, color = "#ccc", ...props }) => {
  return (
    <Container {...props}>
      {[...Array(count).keys()].map((i) => (
        <Ball key={i} index={i} count={count} color={color} />
      ))}
    </Container>
  );
};

export default Loader;
