import styled from "styled-components";

const h = 20;
const w = 20;
const c = 4;
const lw = 1;
const s = Math.floor((h - c * lw) / (c - 1));
const m = (h - s * (c - 1) - lw * c) / 2;

const MainContainer = styled.div`
  display: inline-block;
  width: ${w}px;
  height: ${h}px;
  position: relative;
`;

const Line = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: ${(props) => m + props.$index * (s + lw)}px;
  height: ${lw}px;
  /* background: #fff; */
  border-top: 1px solid #fff;
`;

const ListIcon = (props) => {
  return (
    <MainContainer {...props}>
      {[...Array(c).keys()].map((i) => (
        <Line key={i} $index={i} />
      ))}
    </MainContainer>
  );
};

export default ListIcon;
