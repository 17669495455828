import { useState } from "react";
import styled from "styled-components";
import HTMLContent from "../HTMLContent";
import { useAuth } from "../../context/Auth";
import { saveContent, dataFields, dataTypes } from "../../services/saveContent";
import Notification from "../Notification";
import { Definitie } from "../BodemItem/styled";

const DetailContainer = styled.div`
  ${(props) => props.busy && `cursor: wait;`}
`;
// const KenmerkId = styled.div`
//   float: left;
//   margin-right: 1em;
// `;
const KenmerkDefinitie = styled(Definitie)``;
const KenmerkOmschrijving = styled.div`
  margin-top: 8px;
`;

const KenmerkDetails = ({ id, definitie, omschrijving, type, onEdit }) => {
  const auth = useAuth();
  const { user } = auth;
  const adminMode = user?.adminMode;

  const [busySaving, setBusySaving] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const getHandleSave = (field) => (content) => {
    let dataType = ``;
    if (type === "boven") {
      dataType = dataTypes.kenmerken_boven;
    } else if (type === "onder") {
      dataType = dataTypes.kenmerken_onder;
    }
    if (dataType) {
      setBusySaving(true);
      saveContent(dataType, id, content, field)
        .then((result) => {
          // console.log("saveContent result:", field, result);
          // setStoredData((prev) => ({ ...prev, [field]: content }));
          setSaveError(false);
        })
        .catch((serviceError) => {
          setSaveError(serviceError.message || true);
          console.error(serviceError);
        })
        .finally(() => {
          setBusySaving(false);
          onEdit?.(content);
        });
    } else {
      setSaveError(
        "Kon de gegevens niet opslaan. Interne error: datatype niet gevonden."
      );
    }
  };

  return (
    <DetailContainer busy={busySaving}>
      <Notification show={saveError} type="error">
        Er ging helaas iets mis bij het opslaan van de gegevens.
        {Boolean(saveError && saveError !== true) && (
          <>
            <br />
            <code>{saveError}</code>
          </>
        )}
      </Notification>

      {/* <KenmerkId>{id}</KenmerkId> */}
      {definitie || adminMode ? (
        <KenmerkDefinitie>
          <HTMLContent
            initialValue={definitie}
            onSave={getHandleSave(dataFields.definitie)}
            editable={adminMode}
          />
        </KenmerkDefinitie>
      ) : null}
      {omschrijving || adminMode ? (
        <KenmerkOmschrijving>
          <HTMLContent
            initialValue={omschrijving}
            onSave={getHandleSave(dataFields.omschrijving)}
            editable={adminMode}
          />
        </KenmerkOmschrijving>
      ) : null}
    </DetailContainer>
  );
};

export default KenmerkDetails;
