import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useAuth } from "../context/Auth";
import {
  CollapsePanel,
  CollapseHeader,
  CollapseContent,
} from "./CollapsePanel";

const Notififation = styled.div`
  border: 1px solid blue;
  padding: 0.25em 0.5em;
  margin-top: 16px;
`;

const JSLink = styled.span`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
`;

const User = () => {
  const { user, changePassword, verify } = useAuth();

  const [changePwdPanelOpen, setChangePwdPanelOpen] = useState(false);
  const [newpassword, setNewpassword] = useState("");
  const [busy, setBusy] = useState(false);
  const [notification, setNotification] = useState(null);
  const [reloadNotification, setReloadNotification] = useState(false);

  useEffect(() => {
    return () => {
      verify();
    };
  }, [verify]);

  const togglePwdPanel = () => {
    setChangePwdPanelOpen((prev) => !prev);
  };

  const handleTypeNewPassword = (ev) => {
    const newValue = ev.target.value;
    setNewpassword(newValue);
    if (notification) {
      setNotification(null);
    }
  };

  const handleChangePassword = () => {
    setBusy(true);
    if (notification) {
      setNotification(null);
    }
    changePassword(newpassword)
      .then(() => {
        setNotification("Je wachtwoord is gewijzigd.");
        setReloadNotification(true);
      })
      .catch((err) => {
        console.error(err);
        setNotification(
          `Het is helaas niet gelukt om je wachtwoord te wijzigen. ${err.message}`
        );
      })
      .finally(() => {
        setBusy(false);
      });
    setTimeout(() => {
      setBusy(false);
    }, 2000);
  };

  return (
    <div>
      {reloadNotification && (
        <Notififation>
          Omdat je wachtwoord is gewijzigd moet je opnieuw inloggen.{" "}
          <JSLink
            onClick={() => {
              window.location.reload();
            }}
          >
            Herlaad
          </JSLink>{" "}
          de pagina om opnieuw in te loggen.
        </Notififation>
      )}
      <p>
        Gebruikersnaam: {user.name}
        <br />
      </p>

      {user?.adminMode && (
        <>
          <p>
            Je bent ingelogd. Als je op de webpagina's met je muis over
            aanpasbare content gaat zal in de rechter bovenhoek een 'edit' knop
            verschijnen. Hier kun je op klikken om de content aan te passen.
          </p>
          <p>
            Ga terug naar de <Link to="/">homepage</Link> om te beginnen.
          </p>
        </>
      )}

      <CollapsePanel open={changePwdPanelOpen}>
        <CollapseHeader onClick={togglePwdPanel}>
          Change password
        </CollapseHeader>
        <CollapseContent height={30 + (notification ? 60 : 0)}>
          <label htmlFor="newpassword">New password</label>
          <input
            type="password"
            value={newpassword}
            onChange={handleTypeNewPassword}
          />
          <button disabled={busy} onClick={handleChangePassword}>
            Change
          </button>
          <br />
          {notification && <Notififation>{notification}</Notififation>}
        </CollapseContent>
      </CollapsePanel>
    </div>
  );
};

export default User;
