import styled from "styled-components";

export const CollapsePanel = styled.div.attrs((props) => ({
  className: props.open ? "open" : null,
}))``;

export default CollapsePanel;

export const CollapseHeader = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  user-select: none;
  cursor: pointer;

  &::before {
    content: " ";
    display: inline-block;

    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;

    vertical-align: middle;
    margin-right: 0.7rem;
    margin-left: 3px;
    transform: translateY(-2px);

    transition: transform 500ms ease-out;

    .open & {
      transform: rotate(90deg) translateX(-3px);
    }
  }
`;

export const CollapseContent = styled.div`
  max-height: 0px;
  overflow: hidden;

  transition: max-height 500ms ease-in-out;

  .open & {
    max-height: ${(props) => (props.height ? `${props.height}px` : `100vh`)};
  }

  & label {
    margin-right: 16px;
  }

  & button {
    margin-left: 8px;
  }
`;
