import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getBodemklasseSlug } from "../services/legendData";

const BodemklasseView = () => {
  const { bodemklasse: bodemklasseId } = useParams();
  const navigate = useNavigate();
  const [bodemklasseSlug, setBodemklasseSlug] = useState(null);

  useEffect(() => {
    if (bodemklasseId) {
      getBodemklasseSlug(bodemklasseId)
        .then((slug) => {
          setBodemklasseSlug(slug);
          navigate(slug);
        })
        .catch(() => {
          setBodemklasseSlug(false);
        });
    }
  }, [bodemklasseId, navigate]);

  if (bodemklasseSlug === false) {
    return <div>Kan bodemklasse {bodemklasseId} niet vinden.</div>;
  }

  return <div>bodemklasse wordt opgezocht...</div>;
};

export default BodemklasseView;
