import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  navigationRow,
  navigationRowHover,
  navigationRowText,
  navigationRowHoverText,
} from "../style/color";

export const MainContainer = styled.div`
  ${(props) => props.busy && `cursor: wait;`}
`;

export const Title = styled.h4``;
export const Definitie = styled.div`
  margin-bottom: 2em;
  /* background: #bee8ff; */
  background: #ecbd8d;
  padding: 16px;
  font-size: 110%;
  font-weight: 400;
  margin-right: 8px;
`;

export const FieldLabel = styled.h5`
  margin-bottom: 1em;
`;

export const FieldLabelKenmerken = styled.h5`
  font-weight: normal;
  font-style: italic;
  margin-bottom: 0.5em;
`;

export const Omschrijving = styled.div``;
export const ChildrenContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
`;

export const NoMarkupLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const navigationStyle = css`
  background: ${navigationRow};
  margin-bottom: 2px;
  color: ${navigationRowText};
  padding: 0.2em 1em;

  &:hover {
    background: ${navigationRowHover};
    color: ${navigationRowHoverText};
  }
`;

export const NavigationLink = styled(NoMarkupLink)`
  display: block;
  ${navigationStyle}
`;

export const NavigationItem = styled.div`
  cursor: pointer;
  ${navigationStyle}
`;

export const NavigationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 12fr;
  grid-gap: 0px;
  grid-row-gap: 2px;
  & > * {
    padding: 0.2em 1em;
  }
`;

export const NavigationGridItemContainer = styled(NoMarkupLink)`
  display: contents;
  & > * {
    background: ${navigationRow};
    padding: 0.2em 0.5em;
  }

  &:hover > * {
    background: ${navigationRowHover};
  }
`;

export const SubHeader = styled.h5`
  margin: 8px 0 8px;
`;
