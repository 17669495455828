import styled from "styled-components";

const colors = {
  info: "blue",
  error: "red",
  warning: "orange",
  success: "green",
  default: "blue",
};

const NotififationContainer = styled.div`
  border: 1px solid ${(props) => colors[props.type] || colors.default};
  padding: 0.25em 0.5em;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Notification = ({ show = true, ...props }) => {
  if (!show) {
    return null;
  }
  return <NotififationContainer {...props}></NotififationContainer>;
};

export default Notification;
