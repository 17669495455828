import { createContext, useContext, useState, useEffect } from "react";
import styled from "styled-components";

const NestedPopupContext = createContext(null);

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

const ContentContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  max-width: 95%;
  max-height: 95%;
  background: white;
  padding: 1em;
  overflow-y: auto;
`;

const CloseButton = styled.div`
  color: #bbb;
  display: block;
  position: absolute;
  top: 0px;
  right: 4px;
  font-size: 36px;
  font-weight: bold;
  padding: 0px 6px 1px;
  transition: color 200ms;

  &:hover {
    color: #000;
    cursor: pointer;
  }
`;

const Popup = ({ children, show, close }) => {
  const [nestedPopupContent, setNestedPopupContent] = useState(null);

  const parentSetNestedPopupContent = useContext(NestedPopupContext);

  useEffect(() => {
    if (parentSetNestedPopupContent) {
      if (show) {
        parentSetNestedPopupContent(children);
      } else {
        parentSetNestedPopupContent(null);
      }
    }

    return () => {
      parentSetNestedPopupContent?.(null);
    };
  }, [children, show, parentSetNestedPopupContent]);

  if (!show || parentSetNestedPopupContent) {
    return null;
  }

  const handleClose = (e) => {
    close();
    e.stopPropagation();
  };

  const handleCloseButton = (e) => {
    if (nestedPopupContent) {
      setNestedPopupContent(null);
    } else {
      close();
    }
    e.stopPropagation();
  };

  return (
    <Overlay onClick={handleClose}>
      <ContentContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <NestedPopupContext.Provider value={setNestedPopupContent}>
          {nestedPopupContent ? nestedPopupContent : children}
        </NestedPopupContext.Provider>
        <CloseButton onClick={handleCloseButton}>&times;</CloseButton>
      </ContentContainer>
    </Overlay>
  );
};

export default Popup;
