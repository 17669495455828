import { fetchEndpoint } from "./legendData";
import { requestOptions } from "./legendData";

const getAuthHeaders = (username, password) => {
  let headers = new Headers();
  const base64 = Buffer.from(username + ":" + password).toString("base64");
  headers.append("Authorization", `Basic ${base64}`);
  return headers;
};

export function checkLogin() {
  return fetchEndpoint("/checkauth");
}

export function login() {
  return fetchEndpoint("/verifyuser");
}

/** Not working, logout not possible: */
export function logout() {
  return new Promise((resolve) => {
    const options = {
      ...requestOptions,
      headers: getAuthHeaders("", ""),
    };
    fetchEndpoint("/verifyuser", undefined, options)
      .catch((e) => {
        console.warn(e);
      })
      .finally(resolve);
  });
}

export function changePassword(newpassword) {
  if (!newpassword || newpassword.length < 6) {
    throw new Error("Wachtwoord moet minstens 6 tekens bevatten!");
  }
  var body = new URLSearchParams();
  body.append("newpassword", newpassword);
  return fetchEndpoint("/verifyuser", undefined, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body,
  });
}

/** not used: */
export function loginWithCredentials(username, password) {
  const options = {
    ...requestOptions,
    headers: getAuthHeaders(username, password),
  };
  return fetchEndpoint("/verifyuser", undefined, options);
}
