import { Outlet } from "react-router";
import styled from "styled-components";
import Header from "./Header";

export const InnerContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1% 2%;
`;

const MainContainer = styled.div`
  box-sizing: border-box;
`;

const MainContainerNoScroll = styled(MainContainer)`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & ${InnerContainer} {
    flex: 1 1 0;
    min-height: 0;
  }
`;

const MainLayoutNoScroll = ({ children, ...props }) => {
  return (
    <MainContainerNoScroll {...props}>
      <Header />
      <InnerContainer>
        <Outlet />
        {children}
      </InnerContainer>
    </MainContainerNoScroll>
  );
};

const MainLayout = ({ noScroll = false, ...props }) => {
  if (noScroll) {
    return <MainLayoutNoScroll {...props} />;
  }

  return (
    <MainContainer {...props}>
      <Header />
      <InnerContainer>
        <Outlet />
      </InnerContainer>
    </MainContainer>
  );
};

export default MainLayout;
