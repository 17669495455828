import styled from "styled-components";
import useAutoKeywords from "../hooks/useAutoKeywords";
import { vlakkenBodemkundigBelang } from "../services/vlakkenBodemkundigBelang";
import verspreidingImg from "../assets/Bodemk_belang.jpg";
import { DataTable, Row, HeaderCell, NoWrapCell } from "./Table/DataTable";

const VerspreidingsMap = styled.img.attrs({ src: verspreidingImg })`
  float: right;
`;

const VlakkenBodemkundigBelang = () => {
  const { Popup, AutoKeywords, autoKeywordsProps, popupProps } =
    useAutoKeywords();

  return (
    <div>
      <VerspreidingsMap />
      <AutoKeywords {...autoKeywordsProps}>
        <h3>Vlakken van bodemkundig belang</h3>
        <p>
          Op de bodemkaart komen gebieden voor waar, door bijzondere
          omstandigheden, de bodem niet getypeerd kan worden. Deze gebieden
          worden beschouwd als vlakken van bodemkundig belang (overige
          onderscheidingen).
        </p>
        <p>
          Op het verspreidingskaartje is goed te zien dat de vlakken van
          bodemkundig belang (zwarte kleur in figuur) verspreid door heel
          Nederland voorkomen. De eenheden 'Water' en 'Bebouwing'
          vertegenwoordigen verreweg het grootste areaal.
        </p>
        <DataTable>
          <thead>
            <Row>
              <HeaderCell>Code</HeaderCell>
              <HeaderCell>Omschrijving</HeaderCell>
            </Row>
          </thead>
          <tbody>
            {vlakkenBodemkundigBelang.map((d) => {
              return (
                <Row key={d.code}>
                  <NoWrapCell>{d.code}</NoWrapCell>
                  <td>{d.omschrijving}</td>
                </Row>
              );
            })}
          </tbody>
        </DataTable>
      </AutoKeywords>
      <Popup {...popupProps} />
    </div>
  );
};
export default VlakkenBodemkundigBelang;
