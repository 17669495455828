import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  getAllHoofdklassen,
  getItemsFromSlug,
  getNavItems,
  getChildItems,
  getHoofdklasse,
  getOrde,
  getGroep,
  getBodemklasse,
  itemTypes,
} from "../../services/legendData";
import { dataTypes } from "../../services/saveContent";
import EntityNavigator from "../EntityNavigator";
import BodemItemContent from "./BodemItemContent";
import Loader from "../common/Loader";
import { SubHeader, Title } from "./styled";
import ListIcon from "../Icons/ListIcon";
import ArrowList from "./ArrowList";
import ResizableFooter from "../ResizableFooter";

const MainContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
export const Head = styled.div``;
export const ScrollContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const rootNavItem = {
  id: "hoofdklassen",
  label: <ListIcon />,
  itemType: "root",
};

const MainItemView = () => {
  const {
    [itemTypes.hoofdklasse]: hoofdklasseSlug,
    [itemTypes.orde]: ordeSlug,
    [itemTypes.groep]: groepSlug,
    [itemTypes.bodemklasse]: bodemklasseSlug,
  } = useParams();

  const [hoofdklassen, setHoofdklassen] = useState([]);

  /** @type {[import('../services/legendData').TItemsFromSlug, function]} useState*/
  const [items, setItems] = useState(null);
  const [childItems, setChildItems] = useState({});
  const [navItems, setNavItems] = useState([rootNavItem]);

  const [activeNavItem, setActiveNavItem] = useState();

  const [loadingStack, setLoadingStack] = useState([]);
  const loading = !activeNavItem || loadingStack.length > 0;
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoadingStack((prev) => [...prev, true]);
    getAllHoofdklassen()
      .then((data) => {
        setHoofdklassen(
          data.map((hoofdklasse) => ({
            ...hoofdklasse,
            itemType: itemTypes.hoofdklasse,
          }))
        );
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoadingStack((prev) => prev.slice(0, -1));
      });
  }, []);

  useEffect(() => {
    let mounted = true;
    setError(null);

    setLoadingStack((prev) => [...prev, true]);
    getItemsFromSlug(hoofdklasseSlug, ordeSlug, groepSlug, bodemklasseSlug)
      .then((data) => {
        if (mounted) {
          setItems(data);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setLoadingStack((prev) => prev.slice(0, -1)));

    return () => {
      mounted = false;
    };
  }, [hoofdklasseSlug, ordeSlug, groepSlug, bodemklasseSlug]);

  useEffect(() => {
    let newNavItems = [
      {
        ...rootNavItem,
        onClick: () => {
          setActiveNavItem(rootNavItem);
        },
      },
    ];
    newNavItems.push(
      ...getNavItems(items).map((nav, itemIndex) => ({
        ...nav,
        onClick: () => {
          setActiveNavItem(nav);
        },
      }))
    );

    setNavItems(newNavItems);
    if (newNavItems.length > 1) {
      /* Don't render root level (hoofdklassen list) before user clicks it
        Set initial active level only when the lower levels are ready
      */
      setActiveNavItem(newNavItems[newNavItems.length - 1]);
    }
  }, [items]);

  useEffect(() => {
    let mounted = true;

    Object.values(navItems)
      .reverse()
      .forEach((nav) => {
        if (
          !childItems[nav.itemType] ||
          childItems[nav.itemType].parentId !== nav.id
        ) {
          getChildItems(nav.itemType, nav.id).then((result) => {
            if (mounted) {
              setChildItems((prev) => ({
                ...prev,
                [nav.itemType]: {
                  parentId: nav.id,
                  items: result,
                },
              }));
            }
          });
        }
      });

    return () => {
      mounted = false;
    };
  }, [navItems, childItems]);

  const reloadFields = () => {
    const fetchFunctions = {
      hoofdklasse: getHoofdklasse,
      orde: getOrde,
      groep: getGroep,
      bodemklasse: getBodemklasse,
    };

    fetchFunctions[activeNavItem.itemType]?.(activeNavItem.item.id).then(
      (result) => {
        if (result?.id) {
          setActiveNavItem((prev) => {
            if (prev?.item?.id === result.id) {
              return { ...prev, item: result };
            }
            return prev;
          });
        } else {
          console.warn("No result");
        }
      }
    );
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const childItemsItems = activeNavItem
    ? childItems?.[activeNavItem.itemType]?.items
    : [];
  const linkBase = {
    root: "/bodemclassificatie/item/",
    [itemTypes.hoofdklasse]: `/bodemclassificatie/item/${hoofdklasseSlug}/`,
    [itemTypes.orde]: `/bodemclassificatie/item/${hoofdklasseSlug}/${ordeSlug}/`,
    [itemTypes.groep]: `/bodemclassificatie/item/${hoofdklasseSlug}/${ordeSlug}/${groepSlug}/`,
    [itemTypes.bodemklasse]: "",
  };
  const dataType = {
    [itemTypes.hoofdklasse]: dataTypes.bodemhoofdklasse,
    [itemTypes.orde]: dataTypes.bodemorde,
    [itemTypes.groep]: dataTypes.bodemgroep,
    [itemTypes.bodemklasse]: dataTypes.bodemklasse,
  };

  return (
    <MainContainer>
      <Head>
        <EntityNavigator items={navItems} activeNavItem={activeNavItem} />
      </Head>
      {activeNavItem?.itemType === "root" ? (
        <ScrollContent>
          <Title>Hoofdklassen</Title>
          <ArrowList
            items={hoofdklassen}
            navItems={navItems}
            setActiveNavItem={setActiveNavItem}
            linkBase={linkBase[activeNavItem.itemType]}
            arrowStartStraight
          />
        </ScrollContent>
      ) : (
        <>
          <ScrollContent>
            {loading ? (
              <Loader />
            ) : (
              <BodemItemContent
                dataType={dataType[activeNavItem.itemType]}
                item={activeNavItem.item}
                onEdit={() => {
                  reloadFields();
                }}
              />
            )}
          </ScrollContent>
          <ResizableFooter>
            {!!childItemsItems?.length && (
              <>
                <SubHeader>Verder door met:</SubHeader>
                <ArrowList
                  items={childItemsItems}
                  navItems={navItems}
                  setActiveNavItem={setActiveNavItem}
                  linkBase={linkBase[activeNavItem.itemType]}
                />
              </>
            )}
          </ResizableFooter>
        </>
      )}
    </MainContainer>
  );
};

export default MainItemView;
