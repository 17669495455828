import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function TinyMCEEditor({
  initialValue,
  preview,
  onSave,
  onCancel,
  height = 500,
  singleLineMarkup = false,
}) {
  const editorRef = useRef(null);

  const [dirty, setDirty] = useState(false);

  const [busy, setBusy] = useState(false);

  let editorSettings = {
    height,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste imagetools wordcount",
    ],
    menubar: "file edit insert view format table tools help",
    toolbar:
      "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
    image_advtab: true,
    content_css: "/tinymce.css",
    body_class: "tinymce-content",
    image_title: true,
    /* enable automatic uploads of images represented by blob or data URIs*/
    automatic_uploads: true,
    /*
      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
      images_upload_url: 'postAcceptor.php',
      here we add custom filepicker only to Image dialog
  */
    file_picker_types: "image",
    /* and here's our custom image picker*/
    file_picker_callback: function (cb, value, meta) {
      var input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");

      /*
          Note: In modern browsers input[type="file"] is functional without
          even adding it to the DOM, but that might not be the case in some older
          or quirky browsers like IE, so you might want to add it to the DOM
          just in case, and visually hide it. And do not forget do remove it
          once you do not need it anymore.
      */

      input.onchange = function () {
        var file = this.files[0];

        var reader = new FileReader();
        reader.onload = function () {
          /*
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
          */
          var id = "blobid" + new Date().getTime();
          var blobCache = editorRef.current.editorUpload.blobCache;
          var base64 = reader.result.split(",")[1];
          var blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };

      input.click();
    },
  };

  if (singleLineMarkup) {
    const singleLineSettings = {
      forced_root_block: false, // deprecated
      valid_elements: "strong/b,i",
      plugins: [
        "charmap",
        "searchreplace code",
        "insertdatetime paste wordcount",
      ],
      menubar: "file edit insert view format tools help",
      toolbar: "undo redo | bold italic",
      image_advtab: false,
    };
    editorSettings = { ...editorSettings, ...singleLineSettings };
  } else {
    const defaultSettings = {
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste imagetools wordcount",
      ],
      menubar: "file edit insert view format table tools help",
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
      image_advtab: true,
    };
    editorSettings = { ...editorSettings, ...defaultSettings };
  }

  useEffect(() => {
    setDirty(false);
  }, [initialValue]);

  const handleSave = () => {
    if (editorRef.current) {
      setBusy(true);
      const content = editorRef.current.getContent();
      setDirty(false);
      editorRef.current.setDirty(false);
      Promise.resolve(onSave(content)).finally(() => {
        setBusy(false);
      });
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handlePreviewClick = () => {
    if (editorRef.current && preview) {
      const htmlString = editorRef.current.getContent();
      preview(htmlString);
    }
  };

  return (
    <>
      <Editor
        apiKey="1pbz2qqem9a4rq98pcla20tum8re2d81u84n9k6heaa7zhiy"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        onDirty={() => setDirty(true)}
        init={editorSettings}
      />

      {preview && <button onClick={handlePreviewClick}>Show preview</button>}

      <button onClick={handleSave} disabled={!dirty || busy}>
        Save
      </button>

      {onCancel && (
        <button onClick={handleCancel} disabled={busy}>
          Cancel
        </button>
      )}
    </>
  );
}
