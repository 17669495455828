import { useState, useCallback, useLayoutEffect } from 'react';

/**
 * Usage:
 * const elRef = useRef(null);
 * const [width, height] = useDimensions(elRef);
 * ...
 *   <div ref={elRef}>...</div>
 * ...
 *
 * @param {*} elRef
 * @returns {number[]} [width, height]
 */
export default function useDimensions(elRef) {
    /** [width, height] */
    const [dimensions, setDimensions] = useState([null, null]);

    const handleResize = useCallback((entries) => {
        if (!Array.isArray(entries)) {
            return;
        }

        const entry = entries[0];
        setDimensions([entry?.contentRect?.width, entry?.contentRect?.height]);
    }, []);

    useLayoutEffect(() => {
        if (!elRef.current) {
            return;
        }

        let RO = new ResizeObserver(handleResize);
        RO.observe(elRef.current);

        return () => {
            RO.disconnect();
            RO = null;
        };
    }, [elRef, handleResize]);

    /** [width, height] */
    return dimensions;
}
