import { useState } from "react";
import PropTypes from "prop-types";
import { itemProps } from "../../services/legendData";
import { saveContent, dataTypes, dataFields } from "../../services/saveContent";
import { useAuth } from "../../context/Auth";
import HTMLContent from "../HTMLContent";
import Notification from "../Notification";
import {
  MainContainer,
  Title,
  FieldLabel,
  Omschrijving,
  Definitie,
} from "./styled";
import BodemItemKenmerken from "./BodemItemKenmerken";

const BodemItemContent = (props) => {
  const { item, dataType, onEdit } = props;
  const { id, naam, definitie, omschrijving, verspreiding, kenmerken } = item;

  const [busySaving, setBusySaving] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const auth = useAuth();
  const { user } = auth;
  const adminMode = user?.adminMode;

  const getHandleSave = (field) => (content) => {
    setBusySaving(true);
    saveContent(dataType, id, content, field)
      .then((result) => {
        // console.log("saveContent result:", field, result);
        // setStoredData((prev) => ({ ...prev, [field]: content }));
        setSaveError(false);
      })
      .catch((serviceError) => {
        setSaveError(serviceError.message || true);
        console.error(serviceError);
      })
      .finally(() => {
        setBusySaving(false);
        onEdit(content);
      });
  };

  return (
    <MainContainer busy={busySaving}>
      <Title>
        {id}
        {naam && ": "}
        {naam}
      </Title>

      <Notification show={saveError} type="error">
        Er ging helaas iets mis bij het opslaan van de gegevens.
        {Boolean(saveError && saveError !== true) && (
          <>
            <br />
            <code>{saveError}</code>
          </>
        )}
      </Notification>

      {definitie || adminMode ? (
        <Definitie>
          <HTMLContent
            // label="definitie"
            singleLineMarkup={dataType === dataTypes.bodemklasse}
            initialValue={definitie}
            onSave={getHandleSave(dataFields.definitie)}
            editable={adminMode}
          />
        </Definitie>
      ) : null}

      <BodemItemKenmerken kenmerken={kenmerken} />

      {omschrijving || adminMode ? (
        <Omschrijving>
          <HTMLContent
            initialValue={omschrijving}
            onSave={getHandleSave(dataFields.omschrijving)}
            editable={adminMode}
          />
        </Omschrijving>
      ) : null}

      {dataType === dataTypes.bodemhoofdklasse &&
      (verspreiding || adminMode) ? (
        <>
          <FieldLabel>Verspreiding</FieldLabel>
          <HTMLContent
            initialValue={verspreiding}
            onSave={getHandleSave(dataFields.verspreiding)}
            editable={adminMode}
          />
        </>
      ) : null}
    </MainContainer>
  );
};
BodemItemContent.propTypes = {
  item: itemProps,
  dataType: PropTypes.string.isRequired, // import { dataTypes } from "../services/saveContent";
  onEdit: PropTypes.func,
};

export default BodemItemContent;
