import styled from "styled-components";
import { NavigationItem } from "../BodemItem/styled";

const MainContainer = styled.div``;

const BegrippenLijst = ({ begrippen, onSelect }) => {
  return (
    <MainContainer>
      {begrippen.map((begrip) => {
        return (
          <NavigationItem
            key={begrip}
            onClick={() => {
              onSelect(begrip);
            }}
          >
            {begrip}
          </NavigationItem>
        );
      })}
    </MainContainer>
  );
};
export default BegrippenLijst;
