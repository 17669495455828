import styled from "styled-components";
import {
  NavigationGrid,
  NavigationGridItemContainer,
} from "../BodemItem/styled";

const ItemId = styled.div``;
const ItemDefinitie = styled.div`
  & p {
    margin: 0;
  }
`;

const Item = ({ id, definitie }) => {
  return (
    <NavigationGridItemContainer to={id}>
      <ItemId>{id}</ItemId>
      <ItemDefinitie
        dangerouslySetInnerHTML={{ __html: definitie }}
      ></ItemDefinitie>
    </NavigationGridItemContainer>
  );
};

const ItemOverview = ({ items, ...props }) => {
  return (
    <div {...props}>
      {/* <p>
        <small>
          <i>Klik op een item voor een uitgebreidere toelichting.</i>
        </small>
      </p> */}
      <NavigationGrid>
        {items &&
          items.map((item) => {
            return <Item key={item.id} {...item} />;
          })}
      </NavigationGrid>
    </div>
  );
};

export default ItemOverview;
