import { FlexDiv, LiteratureLink } from "./styled";
import BegrippenLijst from "./BegrippenLijst";

const BegrippenMain = ({ begrippen, selectBegrip }) => {
  return (
    <>
      {/* <h3>Begrippen</h3> */}
      <p>
        Het systeem van bodemclassificatie van Nederland [
        <LiteratureLink href="https://edepot.wur.nl/117844" target="_blank">
          De Bakker en Schelling, 1989
        </LiteratureLink>
        ] is gebaseerd op bodemkundige begrippen. Veel van deze begrippen zijn
        differentiërende kenmerken bij het definiëren van gronden. Hieronder
        volgt een thesaurus.
      </p>
      <FlexDiv>
        {begrippen?.length && (
          <BegrippenLijst begrippen={begrippen} onSelect={selectBegrip} />
        )}
      </FlexDiv>
    </>
  );
};
export default BegrippenMain;
