import { useEffect, useState } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { getSamengesteldeEenheden } from "../../services/legendData";
import SamengesteldeEenhedenMain from "./Main";
import SamengesteldeEenhedenItemDetails from "./ItemDetails";

import EntityNavigator from "../EntityNavigator";

export { SamengesteldeEenhedenItemDetails, SamengesteldeEenhedenMain };

const SamengesteldeEenheden = () => {
  const [eenheden, setEenheden] = useState([]);
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getSamengesteldeEenheden()
      .then((data) => {
        setEenheden(data);
      })
      .catch(console.error);
  }, []);

  const navItems = [
    {
      id: "root",
      itemType: "root",
      label: "Samengestelde legenda-eenheden",
      onClick: () => {
        navigate(".");
      },
    },
  ];

  if (code) {
    navItems.push({
      id: code,
      // itemType: "root",
      label: code,
    });
  }

  return (
    <div>
      <EntityNavigator
        items={navItems}
        activeNavItem={navItems[navItems.length - 1]}
        style={{ marginBottom: 32 }}
      />
      <Outlet context={{ eenheden }} />
    </div>
  );
};
export default SamengesteldeEenheden;
