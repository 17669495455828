import { hellingklassen } from "../services/hellingklassen";
import { DataTable, HeaderCell, NoWrapCell, Row } from "./Table/DataTable";

const Helling = () => {
  return (
    <div>
      <h3>Hellingklasse</h3>
      <p>
        De hellingklasse is op de bodemkaart alleen onderscheiden bij gronden
        die liggen in het heuvelland van Zuid-Limburg. De hellingklasse is daar
        als een apart attribuut aan het einde van de bodemcode geplaatst. De
        volgende hellingklassen zijn onderscheiden:
      </p>

      <DataTable>
        <thead>
          <Row>
            <HeaderCell>Code</HeaderCell>
            <HeaderCell>Omschrijving</HeaderCell>
          </Row>
        </thead>
        <tbody>
          {hellingklassen.map((d) => {
            return (
              <Row key={d.code}>
                <NoWrapCell>{d.code}</NoWrapCell>
                <td>{d.omschrijving}</td>
              </Row>
            );
          })}
        </tbody>
      </DataTable>
    </div>
  );
};

export default Helling;
