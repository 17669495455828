import styled from "styled-components";
import { FieldLabelKenmerken } from "./styled";

const Main = styled.div`
  margin-bottom: 24px;
`;

const BodemItemKenmerken = ({ kenmerken }) => {
  if (!kenmerken?.length) {
    return null;
  }

  return (
    <Main>
      <FieldLabelKenmerken>kenmerken:</FieldLabelKenmerken>
      {kenmerken.map((kenmerk) => {
        return (
          <div key={kenmerk.key}>
            {kenmerk.key}: {kenmerk.value}
          </div>
        );
      })}
    </Main>
  );
};

export default BodemItemKenmerken;
